import { useEffect, useContext } from "react"
import { cancelActions } from "./ROS/ROSFunctions"
import Context from "./Context"

const Safety = () => 
{
  const context = useContext(Context)

  // cancel all actions when a person is detected
  useEffect(() => {
    if (context.person?.new_person && context.currentMission?.hasUV) {
      console.log("found person when uv is on", context.person)
      cancelActions({stopAll: true})
    }
  }, [context.person, context.currentMission])

  return null
}

export default Safety