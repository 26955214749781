import React, { useState } from "react"
import Joystick from "../components/Joystick"

function JoystickManual() {

  const [hand, setHand] = useState(false)

  return (
    <>
      <div style={{minHeight: "14rem"}}>
        <div className={ hand ? "is-pulled-left" : "is-pulled-right" }>
          <Joystick />
        </div>
      </div>
    </>
  )
}

export default JoystickManual
