/**
 * Utils to read ROS map messages and draw them to canva elements.
 * 
 * @class OccupancyGridVisualizer
 */
export default function OccupancyGridVisualizer() { }

/**
 * Reads a OccupancyGrid message and draws it to a canvas element.
 * 
 * @method drawRaw
 * @static
 * @param {Message} msg Message to read.
 * @param {Canvas} canvas Canvas element to output data.
 */
OccupancyGridVisualizer.drawRaw = function (msg, canvas) {
	canvas.width = msg.info.width;
	canvas.height = msg.info.height;

	var context = canvas.getContext("2d");
	context.imageSmoothingEnabled = false;

	var image = context.createImageData(canvas.width, canvas.height);

	for (var i = 0, j = 0; i < msg.data.length; i++, j += 4) {
		var val = msg.data[i];

		image.data[j] = val;
		image.data[j + 1] = val;
		image.data[j + 2] = val;
		image.data[j + 3] = 255;
	}

	context.putImageData(image, 0, 0);
};

/** 
 * Draw occupancy grid as a map.
 * 
 * @method drawMap
 * @static
 * @param {Message} msg Message to read.
 * @param {Canvas} canvas Canvas element to output data.
 */
OccupancyGridVisualizer.drawMap = function (msg, canvas) {
	var width = msg.info.width;
	var height = msg.info.height;

	canvas.width = width;
	canvas.height = height;

	var context = canvas.getContext("2d");
	context.imageSmoothingEnabled = false;

	var image = context.createImageData(canvas.width, canvas.height);

	for (var row = 0; row < height; row++) {
		for (var col = 0; col < width; col++) {
			var data = msg.data[col + ((height - row - 1) * width)];
			var val = data === 100 ? 0 : data === 0 ? 255 : 127;

			var i = (col + (row * width)) * 4;
			image.data[i] = val;
			image.data[++i] = val;
			image.data[++i] = val;
			image.data[++i] = 255;
		}
	}

	context.putImageData(image, 0, 0);
};
