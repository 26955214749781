import React, { useState, useEffect, useContext } from "react"
import Context from "../utils/Context"
import ShowMessage from "../components/ShowMessage"

export default function ModalMission() {

  const context = useContext(Context)
  const defaultMission = { robot: context.selectedRobot.name, mapIndex: context.mapIndex }
  const [selectedMission, setSelectedMission] = useState(defaultMission)
  const [selectedAction, setSelectedAction] = useState()
  const [inputValue, setInputValue] = useState()
  const [saveState, setSaveState] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [removeSelected, setRemoveSelected] = useState(false)
  const [min, setMin] = useState(0)
  const [max, setMax] = useState(0)
  const [step, setStep] = useState(0)
  const [cacheAction, setCacheAction] = useState([])

  // saveState
  useEffect(() => {
    setSaveState(
      selectedMission &&
        selectedMission.name &&
        selectedMission.actions &&
        selectedMission.actions.length > -1
        ? true
        : false
    )
  }, [selectedMission])



  useEffect(() => {

    validadeRuleValues()
  },
    [selectedAction])

  let modalMessage
  switch (selectedAction?.name) {
    case "pause": modalMessage = "Minimal value is 0.1"; break
    case "desinfect-uv": modalMessage = "Minimal value is 0.1"; break
    case "desinfect-all": modalMessage = "Minimal value is 0.1"; break
    case "rotate": modalMessage = "Insert an integer angle in degrees"; break
    default:
  }

  const comboSelect = (name) => {

    var list = context.availableSubActions.find((e) => e.name === name).values;

    return list
  }

  const getCache = (action) => {

    const found = cacheAction.find(element => element.action === action);

    return found ? found.value : null

  }

  const validAction = (action, value) => {

    var validate = cacheAction

    const indx = validate.findIndex(v => v.action === action.name)
    if (~indx) {
      validate.splice(indx, 1)
      validate.push({ action: action.name, value: value })
      setCacheAction(validate)
    } else {
      validate.push({ action: action.name, value: value })
      setCacheAction(validate)
    }

  }


  // select mission
  const selectMission = (event) => {
    setRemoveSelected(false)
    const mission =
      event.target.value === ""
        ? defaultMission
        : context.availableMissions.find((mission) => mission.name === event.target.value)
    setSelectedMission(mission)
    setIsEditing(true)

  }

  // handle mission selection
  const handleChange = async (target) => {
    setSelectedMission((selected) => ({ ...selected, [target.id]: target.value }))

  }

  // insert or update item to array helper
  const pushToArray = (obj, arr) => {
    const index = arr.findIndex((e) => e.name === obj.name)
    if (index === -1) {
      arr.push(obj)
    } else {
      arr[index] = obj
    }
    return arr
  }

  // save mission
  const save = async () => {
    let msg = "Please fill mission name and add at least 1 action"
    let typeMsg = "error"

    if (saveState) {
      const newMissions = pushToArray(selectedMission, [...context.availableMissions])
      context.actions.setAvailableMissions(newMissions)
      msg = "Mission saved"
      typeMsg = "success"
    }
    context.actions.setMessage({ message: msg, type: typeMsg })
  }

  // select action
  const selectAction = (event) => {

    setInputValue(null)

    setSelectedAction(null)
    if (!selectedMission) setSelectedMission(defaultMission)

    const action = context.availableActions.find(
      (action) => action.name === event.target.value
    )

    //console.log('selecting action', action, event.target.value)


    setInputValue(getCache(action.name))
    setSelectedAction(action)

  }

  const validateRules = () => {

    if ((selectedAction && selectedAction.needsValue && inputValue) || (selectedAction && !selectedAction.needsValue)) {

      if (selectedAction.name === "pause") {
        if (inputValue > 0) {
          return true
        } else {
          context.actions.setMessage({ message: modalMessage, type: "error" })
          return false
        }
      } else if (selectedAction.name === "desinfect-uv" || selectedAction.name === "desinfect-all") {
        if (inputValue > 0) {
          return true
        } else {
          context.actions.setMessage({ message: modalMessage, type: "error" })
          return false
        }
      } else if (selectedAction.name === "rotate") {
        if (Number.isInteger(Number(inputValue))) {
          return true
        } else {
          context.actions.setMessage({ message: modalMessage, type: "error" })
          return false
        }
      } else if (inputValue === "Select destination..." || inputValue === "Select event...") {
        context.actions.setMessage({ message: "Invalid action.", type: "error" })
        return false
      }
      else {
        return true
      }

    } else {
      context.actions.setMessage({ message: "Invalid action.", type: "error" })
      return false
    }
  }

  const validadeRuleValues = () => {

    switch (selectedAction?.name) {
      case "pause": setMin(1); setMax(10000); setStep(1); break
      case "desinfect-uv": setMin(0.1); setMax(1.6); setStep(0.1); break
      case "desinfect-all": setMin(0.1); setMax(1.6); setStep(0.1); break
      case "rotate": setMin(1); setMax(360); setStep(1); break
      case "fork": setMin(0); setMax(3); setStep(1); break
      default:
    }

  }

  // add action
  const addAction = () => {

    if (!validateRules()) {
      return false
    }

    let newAction = { ...selectedAction }

    if (inputValue) newAction = { ...newAction, value: inputValue }
    if (newAction.valueType) delete newAction.valueType

    let actions = selectedMission.actions
      ? selectedMission.actions.map((item) => ({ ...item }))
      : []

    actions = [...actions, newAction]

    setSelectedMission({ ...selectedMission, actions: actions })

  }

  // remove action
  const removeAction = (indexAction) => {
    const actions = [].concat(selectedMission.actions)
    actions.splice(indexAction, 1)
    setSelectedMission({ ...selectedMission, actions: actions })
  }

  // delete mission
  const remove = () => {
    let newMissions = [...context.availableMissions]
    newMissions.splice(context.availableMissions.indexOf(selectedMission), 1)
    context.actions.setAvailableMissions(newMissions)
    clear()
    context.actions.setMessage({ message: "Mission deleted.", type: "success" })
    setRemoveSelected(true)
  }

  // clear for new mission input
  const clear = () => {
    setSelectedMission(defaultMission)
    setIsEditing(true)
  }

  // handle select values
  const handleSelectValue = (event) => {

    validAction(selectedAction, event.target.value)
    setInputValue(event.target.value)

  }


  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card" onClick={(e) => e.stopPropagation()}>

        <header className="modal-card-head">
          <p className="modal-card-title">Missions</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => context.actions.setModalState({ ...context.modalState, missions: false })}
          ></button>
        </header>

        <section className="modal-card-body">

          <div className="field mb-5">
            <label className="label">Missions</label>
            <div className="control">
              <button className="button is-success" onClick={clear} style={{ marginRight: "1rem" }}>
                Create new Mission
              </button>
              <div className="select">
                <select name="mission" onChange={selectMission}
                  defaultValue={"Edit mission..."}>
                  {removeSelected ? (
                    <option selected disabled>Edit mission...</option>
                  ) :
                    <option disabled>Edit mission...</option>
                  }
                  {context.availableMissions?.map((item, i) =>
                    item.robot === context.selectedRobot.name && item.mapIndex === context.mapIndex && (
                      <option key={i}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>

          <div className="field mb-5">
            <label className="label">Name</label>
            <div className="control">
              <input
                id="name"
                type="text"
                className="input"
                placeholder={"New mission name..."}
                value={selectedMission && selectedMission.name ? selectedMission.name : ""}
                onChange={(event) => handleChange(event.target)}
              />
            </div>
          </div>

          <div className="field mb-5">
            <label className="label">Action</label>

            <div className="select mr-3">
              <select name="action" onChange={selectAction} defaultValue={"Add action..."} selected={selectedAction && selectedAction.name ? selectedAction.name : ""}>
                <option disabled>Add action...</option>
                {context.availableActions && context.availableActions.map((item, i) => (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            {selectedAction && selectedAction.valueType === "point" ?
              <div className="select">
                <select name="destination" onChange={handleSelectValue} defaultValue={"Select destination..."} value={inputValue ? inputValue : "Select destination..."}>
                  <option disabled>Select destination...</option>
                  {context.maps && context.maps[context.mapIndex] && context.maps[context.mapIndex].objects?.points?.map((item) => (
                    item.name && item.name.map((n, i) =>
                      <option key={i} value={n}>
                        {n}
                      </option>
                    )))}
                </select>
              </div>
              : selectedAction && selectedAction.valueType === "value" ?
                <input
                  type="number"
                  className="input"
                  placeholder="Value"
                  value={inputValue ? inputValue : ""}
                  onChange={(event) => setInputValue(event.target.value)}
                  style={{ width: "10rem" }}
                  min={min}
                  max={max}
                  step={step}
                />
                : selectedAction && selectedAction.valueType === "onOff" ?
                  <div className="select">
                    <select name="event" onChange={handleSelectValue} defaultValue={"Select event..."} value={inputValue ? inputValue : "Select event..."}>
                      <option disabled>Select event...</option>
                      {comboSelect(selectedAction.valueType).map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  : selectedAction && selectedAction.valueType === "startEnd" ?
                    <div className="select">
                      <select name="event" onChange={handleSelectValue} defaultValue={"Select event..."} value={inputValue ? inputValue : "Select event..."}>
                        <option disabled>Select event...</option>
                        {comboSelect(selectedAction.valueType).map((item, i) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    : selectedAction && selectedAction.valueType === "docking" ?
                      <div className="select">
                        <select name="event" onChange={handleSelectValue} defaultValue={"Select target..."} value={inputValue ? inputValue : "Select target..."}>
                          <option disabled>Select target...</option>
                          {comboSelect(selectedAction.valueType).map((item, i) => (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                      : selectedAction && selectedAction.valueType === "upDown" ?
                        <div className="select">
                          <select name="event" onChange={handleSelectValue} defaultValue={"Select event..."} value={inputValue ? inputValue : "Select event..."}>
                            <option disabled>Select event...</option>
                            {comboSelect(selectedAction.valueType).map((item, i) => (
                              <option key={i} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                        : selectedAction && selectedAction.valueType === "mapList" ?
                          <div className="select">

                        <select className="select mb-2 mr-2" name="maps" onChange={handleSelectValue} defaultValue={"Select Map..."} style={{ minHeight: "2.5rem" }} value={inputValue ? inputValue : "Select Map..."} >
                          <option disabled>Select Map...</option>
                            {context.maps.map((m, i) =>
                              m.robot === context.selectedRobot?.name &&
                              <option key={i} value={m?.name}>
                                {m?.name?.length >= 15 ? m?.name?.slice(0, 12) + "..." : m?.name?.slice(0, 14)}
                              </option>
                            )}
                          </select>
                            
                          </div>
                          : null}

            <button className="button is-light" onClick={addAction}>
              Add
            </button>
          </div>

          {selectedMission && selectedMission.actions &&
            <div className="field mb-5">
              <div className="control">
                <div className="tags">
                  {selectedMission.actions.map((action, index) => (
                    <span key={index} className="tag is-warning">
                      {!action.value
                        ? action.name
                        : action.name + " " + action.value
                      }
                      <button
                        className="delete is-small"
                        onClick={() => removeAction(index)}
                      ></button>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          }

          <div className="field mb-5">
            <div className="control">
              <button className="button is-success" onClick={save} style={{ marginRight: "1rem" }}>
                Save changes
              </button>
              {isEditing ?
                <button className="button is-danger" onClick={remove}>
                  DeleteMission
                </button>
                : null
              }
            </div>
            {selectedMission._id &&
              <div className="control">
                <button className="button is-success" onClick={remove}>
                  Delete
                </button>
              </div>
            }
          </div>

          <ShowMessage />

        </section>
      </div>
    </div>
  )
}

