import { useEffect } from "react"
import { useThree } from "react-three-fiber"
import { DragControls } from "three/examples/jsm/controls/DragControls"

export const DragControl = (props) => {

  const { camera, gl, scene } = useThree()

  useEffect(() => {

    const controls = new DragControls(scene.children.filter(c => c.name && c.name.substr(0, 1) === 'p' && c.name.substr(2, 1) === '-'), camera, gl.domElement)

    controls.minDistance = 3
    controls.maxDistance = 20
    controls.enableRotate = false

    controls.addEventListener("dragend", (event) => props.onDragEnd(event))

    return () => {
      controls.dispose()
    }

  }, [scene.children, camera, gl, props])

  return null
}
