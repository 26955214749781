import React, { useEffect, useContext} from "react"
import api from "../api/api"
import Context from "../utils/Context"
import RobotSelector from "./RobotSelector"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog,faRobot } from '@fortawesome/free-solid-svg-icons'

export default function Network() {

  const context = useContext(Context)

  // first load actions
  useEffect(() => {
    // connect to FMS
    const connectToFms = async () => {

      if (context.settings.fmsConnection) {
        // connect
        try {
          console.log("connecting to fms...")
          //const res = await api.connect("fi-remote", "wgo")
          const res = await api.connect("fi-remote", "(KiWi)mgu[369]remote")
         //const res = await api.connect("fi-fi-remote", "wgomfrofl")
          if (res) console.log("connected to fms")
          if (!res) console.log("invalid credentials")

          if (res.data?.token) {
            context.actions.setApiToken(res.data.token)
            window.apiToken = res.data.token

            api.authCheck()
              .then((response) => {
                response && context.actions.setUser(response.data)
              })
          }
        } catch (error) {
          
          context.actions.setErrorMessage(error.response?.status)
          context.actions.setModalState({ ...context.modalState, disconnect: true })
        }
      }
    }

    connectToFms()

  }, [context.firstLoad])

  return (
    <div className="is-flex is-vcentered is-justify-between mb-5 py-2" style={{ flexWrap: 'wrap', borderBottom: '1px solid #e1e1e1' }}>
      <div className="is-pulled-left">
        <button
          className="button is-link is-active mr-2 mb-2 is-pulled-left"
          onClick={() => context.actions.setModalState({ ...context.modalState, configuration: true  })}
        >
          
          <FontAwesomeIcon icon={faRobot} className="is-small mr-2" />
          Robots
      </button>
        <button
          className="button is-link is-active mr-2 mb-2 is-pulled-left"
          onClick={() => context.actions.setModalState({ ...context.modalState, settingsRemote: !context.modalState.settingsRemote })}
        >
          <FontAwesomeIcon icon={faCog} className="fas  mr-2" />
          Settings
        </button>
      </div>
      <RobotSelector />

    </div>
  );
}
