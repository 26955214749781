import React, { useContext, useEffect} from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faExclamationTriangle, faCheck } from '@fortawesome/free-solid-svg-icons'
import Context from "../utils/Context"

export default function ShowMessage() {

  const context = useContext(Context)
  
  useEffect(() => {

    if(context.message) 
  
      setTimeout(() => context.actions.setMessage(""), 3333)   
      
  }, [context.message])

    if (!context.message) return null
 
  const getIcon = (type) => {
    var icon 
    if(context.message?.type === "success"){
      icon = faCheck
    }else if(context.message?.type === "error"){
      icon = faExclamationTriangle
    }else{
      icon = ""
    }
    
    return icon
  }


      
    return (
        <div className="field mb-5">
         
          <small className={!context.message?.type
            ? "notification is-flex is-hidden"
            : context.message?.type === "error"
              ? "notification is-danger is-flex"
              : "notification is-success is-flex"}>
            <span className="icon mr-2">
                <FontAwesomeIcon icon={getIcon(context.message?.type)} /> 
            </span>
            {context.message?.message}
          </small>
      </div>
    )

}