import React, { useContext } from "react"
import Context from "../utils/Context"

export default function Settings() {
  const context = useContext(Context)

  // update data
  const updateSettings = (item, value) => {
    //console.log("update event", value)
    const val = { ...context.settings, [item]: item === "fmsConnection" || item === "fmsIp" ? value : Number.parseFloat(value) }
    window.settings = val
  // console.log("setting settings", val, context.settings.fmsConnection)
    context.actions.setSettings(val)
  }

 // console.log("fmsConnection", context.settings.fmsConnection)

  return (
    <div>
        <div>
          <div className="control mb-5">
            <label className="label">UV desinfection speed</label>

            <input
              type='number'
              value={context.settings?.uv_vel ? context.settings.uv_vel : ''}
              autoFocus
              onChange={(e) => updateSettings("uv_vel", e.target.value)}
              placeholder='0.0'
              min='0'
              max='1.6'
              step='0.1'
              title='UV desinfection speed'
            />
          </div>

        </div>
      

    </div>
  )
}