import React, { useContext } from "react"
import Context from "../../utils/Context"
import Network from "../../components/Network"
import ROSFunctions from "../../utils/ROS/ROSFunctions"
import ModalMission from "../../components/ModalMission"
import ModalConfiguration from "../../components/ModalConfiguration"
import RobotMenu from "../../components/RobotMenu"
import GlobalModal from "../../components/GlobalModal"
import ModalAlerts from "../../components/ModalAlerts"
import SettingsRemote from "../../components/SettingsRemote"

const Home = () => 
{
  const context = useContext(Context)

  return (
    <div>
      <ROSFunctions />
      <Network />
      <RobotMenu />
      { context.modalState.missions && <ModalMission />}
      { context.modalState.configuration && <ModalConfiguration />}
      <GlobalModal />
      { context.modalState.missions && <ModalMission />}
      { context.modalState.disconnect && (context.errorMessage || context.robotStatus || context.diagnostic) && <ModalAlerts />}
      { context.modalState.settingsRemote && <SettingsRemote />}
      
    </div>
  )
}

export default Home
