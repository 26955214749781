import React, { useContext, useEffect } from "react"
import { useState } from "react"
import Context from "../utils/Context"
import { watchEndOfMission } from "../utils/ROS/ROSFunctions"


const GlobalModal = () => {
  const context = useContext(Context)
  const [missionStatus, setMissionStatus] = useState(null)

  // watch task feedback
  useEffect(() => {
    let topic
    if (context.isRobotConnected) {
      // console.log("global modal: watch task feedback")
      topic = watchEndOfMission(context)
    } else {
      // console.log("global modal: unsubscribe task feedback")
      if (topic) topic.unsubscribe()
    }
    return (() => { if (topic) topic.unsubscribe() })
  }, [context.isRobotConnected])

  // end current mission
 /*useEffect(() => {
    if (context.currentMission && context.endOfMission && context.selectedRobot.typeRobot !== "FI-250") {
      cancelActions({ stopAll: true })
    }
  }, [context.endOfMission, context.currentMission])*/

  // found people
  useEffect(() => {
    if (context.currentMission) {
      if (context.foundPeople) {
        //console.log("found people")
        setMissionStatus("foundPeople")
        context.actions.setCurrentMission(null)
      }
    }
    // console.log("global modal", context.currentMission, context.foundPeople)
  }, [context.foundPeople, context.actions, context.currentMission])

  // reset mission
  const resetMission = () => {
    context.actions.setEndOfMission(null)
    setMissionStatus(false)
    context.actions.setFoundPeople(false)
  }

  // modal message
  let modalMessage
  switch (missionStatus) {
    case "cancelled": modalMessage = "Mission was cancelled"; break
   // case "finished": modalMessage = "Mission has finished successfully"; break
    case "foundPeople": modalMessage = "UV Mission aborted, person detected"; break
    default:
  }

  // test sonae mission generation
  // const generateMission = async () => {
  //   console.log("calling api with data", {robotId: "5f05d2227b2aa706576cbe71", orderCode: "A36BE304-33E7-4A4D-ACEF-42CC50F3B8DF"})
  //   const result = await api.generateSonaeMission({robotId: "5f05d2227b2aa706576cbe71", orderCode: "A36BE304-33E7-4A4D-ACEF-42CC50F3B8DF"})
  //   console.log("generate sonae mission result", result)
  // }
  // useEffect(() => {
  //   generateMission()
  // }, [])

  return (
    <div>

      {(missionStatus && missionStatus !== "executing") &&

        <div id="modal" className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card">

            <header className="modal-card-head">
              <p className="modal-card-title">
                Mission Status
                </p>
              <button className="delete" aria-label="close"></button>
            </header>

            <section className="modal-card-body">
              <p>
                {modalMessage}
              </p>
            </section>

            <footer className="modal-card-foot">
              <button className="button" onClick={resetMission}>
                Ok
                </button>
            </footer>

          </div>
        </div>
      }
    </div>
  )
}

export default GlobalModal
