import React, { useContext, useState, useEffect } from "react"
import Context from "../utils/Context"
import { sendTaskToRobot, cancelActions } from "../utils/ROS/ROSFunctions"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faStopCircle, faCog } from '@fortawesome/free-solid-svg-icons'
import ShowMessage from "../components/ShowMessage"


export default function Missions() {

  const context = useContext(Context)
  const [showWarning, setShowWarning] = useState(false)
  const [showCountdown, setShowCountdown] = useState(false)
  const [countdown, setCountdown] = useState(10)
  const [timer, setTimer] = useState(null)

  // start task
  const startTask = (mission) => {
    if (mission) {
      sendTaskToRobot({ actions: mission.actions, id: mission.name, name: mission.name })
      console.log('publishing mission: ', { actions: mission.actions, id: mission.name, name: mission.name })
    }
  }

  // execute a mission
  const execMission = (missionName) => {
    const mission = context.availableMissions.find(m => m.name === missionName)
    const hasUV = mission?.actions?.find(action => action.name === "desinfect-uv" || action.name === "desinfect-all")

    if (mission) {
     // console.log("found mission", mission)
      context.actions.setCurrentMission({ ...mission, hasUV: hasUV ? true : false })
    }

    if (hasUV) {
      setShowWarning(true)
    } else {
      startTask(mission)
    }
  }

  const stopMission = () => {
    cancelActions({ stopAll: true })
    context.actions.setCurrentMission(null)
    context.actions.setMessage({message: "Mission Stoped!" , type: "success"})  
  }


  // start countdown
  const startCountdown = () => {
    setShowCountdown(true)

    setTimer(
      setInterval(() => {
        setCountdown(c => c - 1)
      }, 1000)
    )
  }

  // end of countdown
  useEffect(() => {
    if (countdown === 0) {
      clearInterval(timer)
      setTimer(null)
      setShowCountdown(false)
      setCountdown(10)
      startTask(context.currentMission)
      setShowWarning(false)
    }
  }, [countdown, context.currentMission, timer])

  return (
    <div>
      <button
        onClick={() => context.actions.setModalState({ ...context.modalState, missions: true })}
        className="button is-success mb-5 mr-3"
        styles={{ marginRight: "1rem" }}
      >
        <FontAwesomeIcon icon={faPlus} className="is-small mr-1" />
        Manage Missions
      </button>

      <button
        onClick={stopMission}
        className="button is-danger mb-5 mr-3"
      >
        <FontAwesomeIcon icon={faStopCircle} className="is-small mr-1" />
        Stop Mission
      </button>

      {/* <div className="is-flex" style={{ flexWrap: "wrap" }}> */}
      {context.availableMissions.length > 0 &&
        context.availableMissions.map((mission, i) => (
          mission.robot === context.selectedRobot.name && mission.mapIndex === context.mapIndex &&
          <button key={i} className="button is-outlined is-link mr-2 mb-2" onClick={() => execMission(mission.name)}>
            {/* to fix */}
            {/* {(context.endOfMission && context.endOfMission.status === "executing" && context.endOfMission.name === mission.name) &&
              <FontAwesomeIcon icon={faCog} className="fas fa-pulse" />
            } */}
            {mission.name + " "}
          </button>
        ))}
      {/* </div> */}

      <ShowMessage />

      <div id="modal" className={showWarning ? "modal is-active" : "modal"}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">UV Light usage confirmation</p>
            <button className="delete" aria-label="close"></button>
          </header>

          <section className="modal-card-body">
            <div className="controls">
              {
                showCountdown ?
                  <div className="control">
                    <p>{countdown} seconds to start...</p>
                  </div>
                  :
                  <div className="control">
                    <p>You will turn on the UV Lights, are you sure?</p>
                    <p>You will have 10 seconds to leave the room and close the door.</p>
                  </div>
              }
            </div>
          </section>

          <footer className="modal-card-foot">
            {!showCountdown &&
              <>
                <button className="button is-success" onClick={startCountdown}>
                  <span className="icon is-small">
                  <FontAwesomeIcon icon={faCog} className="fas" />
                  </span>
                  <span>Confirm</span>
                </button>

                <button className="button" onClick={() => setShowWarning(false)}>
                  Cancel
            </button>
              </>
            }
          </footer>

        </div>
      </div>
    </div>
  )
}