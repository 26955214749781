import React, { useContext } from "react"
import Context from "../utils/Context"

function ModalAlerts() {

    const context = useContext(Context)

    return (

        <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card" >

                <header className="modal-card-head">
                    <p className="modal-card-title">Robot information</p>
                    <button
                        className="delete"
                        aria-label="close"
                        onClick={() => context.actions.setModalState({ ...context.modalState, disconnect: false })}
                    ></button>
                </header>

                <section className="modal-card-body">
                    <div className="field mb-5">
                        <div className="controls">
                            {(context.errorMessage === "disconnect" && (!context.robotStatus))  &&                            
                            
                            <div className="control mb-3">
                                <p>
                                    Cannot establish a connection. 
                                    Please check if the robot is connected. 
                                </p>
                            </div>
                            
                            }
                            {(context.errorMessage === 401 && (!context.robotStatus)) &&                            
                            
                            <div className="control mb-3">
                                <p>
                                    Unauthorized access. Please review you FMS connection settings. 
                                </p>
                            </div>
                            
                            }
                            {(context.errorMessage === 405 && (!context.robotStatus)) &&                            
                            
                            <div className="control mb-3">
                                <p>
                                    Method Not Allowed.
                                </p>
                            </div>
                            
                            }
                            {(context.errorMessage === 404 && (!context.robotStatus)) &&                            
                            
                            <div className="control mb-3">
                                <p>
                                    API not found.
                                </p>
                            </div>
                            
                            }
                            {(context.errorMessage === 400 && (!context.robotStatus)) &&                            
                            
                            <div className="control mb-3">
                                <p>
                                    Failed to load resource from API. Please review you FMS connection settings. 
                                </p>
                            </div>
                            
                            }
                            {context.robotStatus &&                            
                            
                            <div className="control mb-3">
                                <p>
                                    Robot blocked, please remove any obstacles present. 
                                </p>
                            </div>
                            
                            }
                            {context.diagnostic &&                            
                            
                            <div className="control mb-3">
                                <p>
                                    Diagnostic Log:  {context.diagnostic?.data}
                                </p>
                            </div>
                            
                            }
                        </div>
                    </div>
                
                </section>
                <footer className="modal-card-foot">
                    <button className="button is-link" onClick={() => context.actions.setModalState({ ...context.modalState, disconnect: false })}>
                        Close
                    </button>
                </footer>
            </div>
        </div>
    )
}

export default ModalAlerts
