import React from "react"

const ModalMap = (props) => 
{
  return (
    <div className="controls">
      <div className="control mb-3">
        <label>Map name:</label>
        <input
          type="text"
          value={props.mapName ? props.mapName : ""}
          autoFocus
          className="input"
          onChange={(e) => props.update("mapName", e.target.value)}
        />
      </div>
    </div>
  )
}

export default ModalMap
