import React from "react"

export const Point = (props) => {

  let position = [props.position.x, props.position.y, 2]

  return (
    <mesh visible name={props.name} position={position} rotation={[0, 0, 0]} scale={[0.2, 0.2, 0.2]}>
      <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
      <meshStandardMaterial attach="material" color={props.color} transparent />
    </mesh>
  )
}
