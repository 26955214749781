import React, { useContext, useState, useEffect } from "react"
import Context from "../../utils/Context"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faPlayCircle, faCheck, faDolly } from '@fortawesome/free-solid-svg-icons'
import { sendTaskToRobot, cancelActions } from "../../utils/ROS/ROSFunctions"

const DashboardMissionStatus = (props) => {

  const context = useContext(Context)
  const [indexOrder, setIndexOrder] = useState(0)
  const [confirmation, setConfirmation] = useState(false)



  const addEndDestination = (position) => {

    //var postionValue = position.substr(position.length - 3)
    // var postionValue = position.toString().slice(-3)
    // var finalPostion


    // switch (true) {
    //   case postionValue <= 100: finalPostion = "D1"; break
    //   case postionValue > 100 && postionValue < 200: finalPostion = "D2"; break
    //   case postionValue > 200 && postionValue < 300: finalPostion = "D3"; break
    //   case postionValue > 300 && postionValue < 400: finalPostion = "D4"; break
    //   case postionValue > 400 && postionValue < 500: finalPostion = "D5"; break
    //   case postionValue > 500 && postionValue < 600: finalPostion = "D6"; break
    //   case postionValue > 600 && postionValue < 700: finalPostion = "D7"; break
    //   case postionValue > 700 && postionValue < 800: finalPostion = "D8"; break
    //   case postionValue > 800 && postionValue < 900: finalPostion = "D9"; break
    //   case postionValue > 900 && postionValue <= 999: finalPostion = "D10"; break
    //   default:

    // }

    // return finalPostion
    return "D1"
  }

  const closeValidation = (type) => {

    if (type) {
      if (context.currentMission) {
        setConfirmation(true)
      } else {
        context.actions.setModalState({ ...context.modalState, dashboard_status: false, dashboard: true })
      }
    } else {
      cancelActions({ stopAll: true })
      context.actions.setModalState({ ...context.modalState, dashboard_status: false, dashboard: true })
    }
  }

  const lastDestination = addEndDestination(props.attributes[0].task)

  // send mission to robot
  useEffect(() => {
    let actions = []
    props.attributes.map(item => {
      actions.push({ name: "go-to", value: item.position.substr(0, 8) })
      actions.push({ name: "hold" })
    })
    actions.push({ name: "go-to", value: lastDestination })
    actions.push({ name: "stop" })

    const mission = { actions: actions, id: "esmera", name: "esmera" }

    sendTaskToRobot(mission)
    //console.log("sending mission to robot", mission)
    context.actions.setCurrentMission(mission)

  }, [])


  // go to next destination
  const moveForward = () => {
    cancelActions({ stopHold: true })

    let newIndex = indexOrder
    newIndex++
    setIndexOrder(newIndex)

    // end order and close modal
    if (newIndex > (props.attributes.length)) {
      context.actions.setModalState({ ...context.modalState, dashboard_status: false, dashboard: true })
      return false
    }

  }

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card" >
        {confirmation ? (
          <>
            <header className="modal-card-head">
              <p className="modal-card-title">Stop order </p>
            </header>
            <section className="modal-card-body">
              <div className="field mb-5">
                <div className="controls">
                  Do you want to close? If you close the order will be stopped.
                </div>
              </div>
            </section>
            <footer className="modal-card-foot">
              <div className="control">
                <button className="button is-success" onClick={() => closeValidation(false)}>
                  Yes
                    </button>
                <button className="button is-danger" onClick={() => setConfirmation(false)}>
                  No
                    </button>
              </div>
            </footer>


          </>
        ) : (
          <>
            <header className="modal-card-head">
              <p className="modal-card-title">Dashboard </p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => closeValidation(true)}
              ></button>
            </header>

            <section className="modal-card-body">

              <article className="message">
                <div className="message-header">
                  <div className="columns is-info">
                    <div className="column has-text-light">
                      Order : {props.attributes ? props.attributes[0].order : ""}
                    </div>
                  </div>
                </div>
                <div className="message-body">
                  <div className="columns is-multiline is-mobile has-background-primary-dark">
                    <div className="column is-one-quarter has-text-light">
                      Description
                  </div>
                    <div className="column is-one-quarter has-text-light">
                      SKU
                  </div>
                    <div className="column is-one-quarter has-text-light">
                      Position
                  </div>
                    <div className="column is-one-quarter has-text-light">
                      Status
                  </div>
                  </div>
                  {Array.isArray(props.attributes) && props.attributes.map((attribute, index) => (
                    <div className="columns is-multiline is-mobile is-centered">
                      <div className="column is-one-quarter">
                        {attribute.description.length > 30 ? attribute.description.slice(0, 30) + "..." : attribute.description.slice(0, 30)}
                      </div>
                      <div className="column is-one-quarter">
                        {attribute.sku}
                      </div>
                      <div className="column is-one-quarter">
                        {attribute.position}
                      </div>
                      <div className="column is-one-quarter has-background-success-light">

                        {
                          index === indexOrder && context.orderStatus ?
                            <button className="button is-link is-flex container" >
                              <FontAwesomeIcon icon={faCog} className="fas fa-pulse mr-2" /> <p>Executing</p>
                            </button>

                            : index < indexOrder ?
                              <button className="button is-success is-flex container">
                                <FontAwesomeIcon icon={faCheck} />
                              </button>

                              : index === indexOrder ?
                                <button className="button is-success is-flex is-pulled-right ml-5" onClick={moveForward}>
                                  <FontAwesomeIcon icon={faPlayCircle} className="mr-2" /> <p> Next</p>
                                </button>

                                : null
                        }

                      </div>
                    </div>
                  ))}
                  <div className="columns is-multiline is-mobile is-centered">
                    <div className="column is-one-quarter">
                      Unloading
                  </div>
                    <div className="column is-one-quarter">

                    </div>
                    <div className="column is-one-quarter">
                      {lastDestination}
                    </div>
                    <div className="column is-one-quarter has-background-success-light">
                      {context.orderStatus && indexOrder === (props.attributes.length) ?
                        (
                          <button className="button is-link is-flex container" >
                            <FontAwesomeIcon icon={faCog} className="fas fa-pulse mr-3" />
                            <FontAwesomeIcon icon={faDolly} className="fas" />
                          </button>

                        ) : !context.orderStatus && indexOrder === (props.attributes.length) ?
                          (
                            <button className="button is-success is-flex container" onClick={moveForward}>
                              <FontAwesomeIcon icon={faDolly} className="fas mr-3" />
                              <FontAwesomeIcon icon={faCheck} />
                            </button>
                          ) : (
                            null
                          )
                      }

                    </div>
                  </div>
                </div>
              </article>
            </section>
          </>
        )}
      </div >
    </div >
  )
}
export default DashboardMissionStatus


