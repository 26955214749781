import React, { Component } from "react"

export default class CompareArray extends Component {


    constructor(props) {
        super(props);
    }

    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.

    compareArrays = (a, b, compareFunction) => {
        return a.filter(aValue =>
            !b.some(bValue =>
                compareFunction(aValue, bValue))
        )
    }


}