import React, { useState, useEffect, useCallback } from "react"
import { useThree } from "react-three-fiber"
import * as THREE from "three"
import { Point } from "./Point"
import { Controls } from "./Controls"
import { DragControl } from "./DragControl"

export function Map(props) {

  const [isDragging, setIsDragging] = useState(false)
  const { camera, scene } = useThree()
  const {
    canvasRef,
    openModal,
    updateCanvas,
    context,
    robotPos,
  } = props

  // console.log("render robot position", context.robotPosition)

  useEffect(() => {
    if(context.selected.tool?._id === "location" && context.robotPosition) {
        
        let counter = context.pointCounter + 1
        context.actions.setPointCounter(counter)
        let newMapObjects = {...context.mapObjects}

        if (!newMapObjects.points) newMapObjects.points = [] // initialize if needed
        newMapObjects.points.push({ counter: "pn-" + String(counter), point: context.robotPosition.position })
        context.actions.setSelected({...context.selected, mapObject: {type: "points", idx: (newMapObjects.points.length - 1)}})
        context.actions.setHelpers({...context.helpers, newItem: false, newObject: true, editing: false})
        context.actions.setMapObjects(newMapObjects)
        context.actions.setSelected({ tool: { _id: 'points', name: 'Navigation' }})

    }
  }, [context.selected.tool?._id])


  const intersectPlane = (clientX, clientY) => {
    if (canvasRef.current) {
      const map = canvasRef.current.getBoundingClientRect()
      var raycaster = new THREE.Raycaster()
      var mouse = new THREE.Vector2()
      mouse.x = ((clientX - map.left) / map.width) * 2 - 1
      mouse.y = (-(clientY - map.top) / map.height) * 2 + 1
      raycaster.setFromCamera(mouse, camera)
      return raycaster.intersectObjects(scene.children)
    }
  }

  // save point -----------------------------------------------------------------------------------------

  const mouseUp = useCallback(e => {
    
    const touch = Array.isArray(e.touches) && e.touches.length > 0 ? e.touches[0] : null
    const intersects = intersectPlane(touch ? touch.clientX : e.clientX, touch ? touch.clientY : e.clientY)
    const totalObjects = intersects?.length
    const clickedPoint = intersects?.find(i => i.object?.type === "Mesh" && i.object.name !== "map")
    const clickedEdge = intersects?.find(i => i.object?.type === "Line")
    //console.log("clicked edge", clickedEdge)
    const edgeType = clickedEdge?.object.name?.substr(0, 3) === "ln-" ? 'points' :
      clickedPoint?.object.name.substr(0, 3) === "lz-" ? 'zones' : 
        clickedPoint?.object.name.substr(0, 3) === "lf-" ? 'no_go' :
          null
    const pointType = clickedPoint?.object.name.substr(0, 3) === "pn-" ? 'points' :
      clickedPoint?.object.name.substr(0, 3) === "pz-" ? 'areas' : 
        clickedPoint?.object.name.substr(0, 3) === "pf-" ? 'no_go' :
          null

    //console.log("intersects", intersects, totalObjects, clickedPoint, pointType)

    // exit if is dragging
    if (isDragging) {
      setIsDragging(false)
      //console.log("detected is dragging")
      if (context.selected.tool?._id === "no_go") return false
    }

    if (totalObjects > 0) { // click detected inside map area

     // console.log("click detected inside map area")
    
      if (clickedPoint) { // a point was clicked

     //   console.log("a point was clicked")

        switch (pointType) {

          case "points": // the clicked point is a nav point

         //   console.log("the clicked point is a nav point", clickedPoint.object.name)

            // find clicked nav point
            let pointIndex = context.mapObjects.points.findIndex(point => point.counter === clickedPoint.object.name)
            let pointData = context.mapObjects.points[pointIndex]
          //  console.log("found point data", pointData, pointIndex, context.selected.connect)

            // connect to point: create edge
            if (context.selected.connect && pointData?.name && pointData.name[0] !== context.selected.connect.from) {

            //  console.log("connecting to point", pointData.name[0])
              let newObjects = {...context.mapObjects}
              if (!newObjects.edges) newObjects.edges = []
              let ecounter = context.pointCounter + 1
              context.actions.setPointCounter(ecounter)
              let newEdge = {from: context.selected.connect.from, to: pointData.name[0], counter: "ln-" + String(ecounter), reversable: true, avoid: true}
           //   console.log("new edge", newEdge)
              newObjects.edges.push(newEdge)
              context.actions.setMapObjects(newObjects)

              // open edge modal
              context.actions.setSelected({
                ...context.selected, 
                mapObject: {type: "edges", idx: (newObjects.edges.length - 1)},
                connect: {...context.selected.connect, to: pointData.name[0]}
              })
           //   console.log("open edge modal", newEdge)
              openModal("edges", newEdge)
              break
            }

            // open point modal
            if (pointData) {
              context.actions.setHelpers({...context.helpers, editing: true})
              context.actions.setSelected({...context.selected, mapObject: {type: "points", idx: pointIndex}})
            //  console.log("open point modal")
              openModal("points", pointData)
            }
            break

          case "areas": // the clicked point is an area point

          //  console.log("the clicked point is an area point", clickedPoint.object.name, context.mapObjects.areas)

            // find clicked area
            let areaIndex, pIndex
            context.mapObjects.areas && context.mapObjects.areas.forEach((area, idx) => {
              area.polygon && area.polygon.map((point, idy) => { // store area index of the clicked point
                if (point.counter === clickedPoint.object.name) {
                  areaIndex = idx
                  pIndex = idy
                }
              })
            })
            let areaData = context.mapObjects.areas[areaIndex]
          //  console.log("found area data", areaData, areaIndex)

            // connect to point
            if (context.selected.tool?._id === "areas") {
              let newObjects = {...context.mapObjects}
              newObjects.areas[areaIndex].polygon.push(newObjects.areas[areaIndex].polygon[pIndex]) // push clicked point
              context.actions.setMapObjects(newObjects)
              break
            }

            // open edit modal for this area
            if (areaData) {
              context.actions.setHelpers({...context.helpers, editing: true})
              context.actions.setSelected({...context.selected, mapObject: {type: "areas", idx: areaIndex}})
              openModal("areas", areaData)
            }
            break

          case "no_go": // the clicked point is a no_go point

           // console.log("the clicked point is a no_go point", clickedPoint.object.name)

            // find point
            let newObjects = {...context.mapObjects}

            let newPoint
            newObjects.no_go.forEach((area, idx) => {
              if (Array.isArray(area.polygon))
                area.polygon.forEach((point, idy) => {
                  if (point.counter === clickedPoint.object.name)
                    newPoint = point
                })
            })

            // connect to clicked point            
            if (newPoint && context.selected.tool?._id === "no_go") {
              newObjects.no_go[newObjects.no_go.length - 1].polygon.push(newPoint)
              context.actions.setMapObjects(newObjects)
            }

            break

          default:
        }

        return false
      } 

      // clicked on edge

      if (clickedEdge) {

      //  console.log("an edge was clicked", edgeType)

        switch (edgeType) {

          case "points": // the clicked edge is a nav edge

          //  console.log("the clicked edge is a nav edge", clickedEdge.object.name)

            // find clicked nav edge
            let edgeIndex = context.mapObjects.edges.findIndex(e => e.counter === clickedEdge.object.name)
            let edgeData = context.mapObjects.edges[edgeIndex]
          //  console.log("found edge data", edgeData, edgeIndex)

            // open edit modal if nav edge
            if (edgeData) {
              context.actions.setHelpers({...context.helpers, editing: true})
              context.actions.setSelected({
                ...context.selected, 
                mapObject: {type: "edges", idx: edgeIndex},
                connect: {from: edgeData.from, to: edgeData.to, name: edgeData.name}
              })
              openModal("edges", edgeData)
            }
            break

          case "areas": // the clicked edge is an area edge

          //  console.log("the clicked edge is an area edge", clickedEdge.object.name)

            // find clicked area
            let areaIndex = parseInt(clickedEdge.object.name.substr(1))
            let areaData = context.mapObjects.areas[areaIndex]
          //  console.log("found area data", areaData, areaIndex)
            
            // open edit modal for this area
            if (areaData) {
              context.actions.setHelpers({...context.helpers, editing: true})
              context.actions.setSelected({...context.selected, mapObject: {type: "areas", idx: areaIndex}})
              openModal("areas", areaData)
            }
            break

          default:
        }
        
        return false
      }

      // new point

      if (context.selected.tool?._id) {

        let pointObject = intersects[0].point
        let point = pointObject
        let counter = context.pointCounter + 1
        context.actions.setPointCounter(counter)
        let newMapObjects = {...context.mapObjects}

       // console.log("creating new point", pointObject, point, counter, context.selected.tool, context.helpers.newItem)

        // insert new point by type on data structure
        if (context.selected.tool._id === "points") { // new nav point

          if (!newMapObjects.points) newMapObjects.points = [] // initialize if needed
          newMapObjects.points.push({ counter: "pn-" + String(counter), point: point })
          context.actions.setSelected({...context.selected, mapObject: {type: "points", idx: (newMapObjects.points.length - 1)}})

        } else { // new area points or no_go points

          let prefix = context.selected.tool._id === "areas" ? "pz-" : "pf-"
          let newPoint = { counter: prefix + String(counter), point: pointObject }

          if (context.helpers.newItem) {

            let newObject = { polygon: [] }
            newObject.polygon.push(newPoint)

            // initialize if needed
            if (!newMapObjects[context.selected.tool._id]) {
              if (context.selected.tool._id === "areas") 
                newMapObjects.areas = [] 
              else
                newMapObjects.no_go = []
            }

            newMapObjects[context.selected.tool._id].push(newObject)

          } else {

            newMapObjects[context.selected.tool._id][newMapObjects[context.selected.tool._id].length-1].polygon.push(newPoint)

          }

         // console.log("new map objects", newMapObjects)
        }

        // open modal
        if (context.selected.tool._id === "points") openModal(context.selected.tool._id)

        if (context.selected.tool._id === "areas" && context.helpers.newItem) {
          context.actions.setSelected({...context.selected, connect: true, mapObject: {type: "areas", idx: (newMapObjects.areas.length - 1)}})
          openModal("areas")
        }

        if (context.selected.tool._id === "no_go") context.actions.setSelected({...context.selected, connect: true})

        context.actions.setHelpers({...context.helpers, newItem: false, newObject: true, editing: false})
        context.actions.setMapObjects(newMapObjects)
      }

    } 

  }, [context.selected.tool, context.mapObjects, context.helpers.newItem, isDragging, context.selected.connect])

  // areas and no_go edges ----------------------------------------------------------------------------------

  const onUpdate = (points) => self => self.setFromPoints(points.map(p => (p.point)))

  // update points after drag -------------------------------------------------------------------------------

  const updatePoint = (event) => {

    setIsDragging(true)
    let found = false, foundIdx, foundIdy
    let newObjects = {...context.mapObjects}

   //("updating point after drag", newObjects)

    // search in nav points
    newObjects.points && newObjects.points.forEach((point, idx) => {
      if (point.counter === event.object.name) {
        found = true
        foundIdx = idx
      }
    })

    if (found) { // update point coordinates and exit if found
      newObjects.points[foundIdx].point = event.object.position
    //  console.log("found drag point", event.object.position)
      context.actions.setMapObjects(newObjects)
      return false
    }

    // search in area points
    newObjects.areas && newObjects.areas.forEach((area, idx) => {
      if (Array.isArray(area.polygon)) area.polygon.forEach((point, idy) => {
        if (point.counter === event.object.name) {
          found = true
          foundIdx = idx
          foundIdy = idy
        }
      })
    })

    if (found) { // update point coordinates and exit if found
      newObjects.areas[foundIdx].polygon[foundIdy].point = event.object.position
      context.actions.setMapObjects(newObjects)
      return false
    }

    // search in no_go points
    newObjects.no_go && newObjects.no_go.forEach((area, idx) => {
      if (Array.isArray(area.polygon)) area.polygon.forEach((point, idy) => {
        if (point.counter === event.object.name) {
          found = true
          foundIdx = idx
          foundIdy = idy
        }
      })
    })

    if (found) { // update point coordinates and exit if found
      newObjects.no_go[foundIdx].polygon[foundIdy].point = event.object.position
      // console.log("found drag no_go point", foundIdx, foundIdy, newObjects)
      context.actions.setMapObjects(newObjects)
      return false
    }
  }

  // populate nav edges -------------------------------------------------------------------------------------

  let edges = []
  // console.log("populating edges", context.mapObjects)
  context.mapObjects.edges && context.mapObjects.edges.forEach(e => {
    let from = context.mapObjects.points?.find(p => p.name && p.name[0] !== undefined && p.name[0] === e.from)?.point
    let to = context.mapObjects.points?.find(p => p.name && p.name[0] !== undefined && p.name[0] === e.to)?.point
   // console.log("edge name", e.counter)
    if (from && to) {
      let newEdge = {name: e.counter, points: []}
      from = {...from, z: 1}
      to = {...to, z: 1}
      newEdge.points.push({point: from})
      newEdge.points.push({point: to})
      edges.push(newEdge)
    } else {
    //  console.log("failed to populate edge", e.counter, from, to)
    }
  })

  // plane ------------------------------------------------------------------------------------

  let geometry = new THREE.PlaneBufferGeometry(1, 1)
  var matrix = new THREE.Matrix4()
  matrix.makeTranslation(0.5, 0.5, 0)
  geometry.applyMatrix4(matrix)

  // update Maps.js canvas ---------------------------------------------------------------------

  useEffect(() => {
    let canvas = new THREE.CanvasTexture(document.createElement("canvas"))
    canvas.generateMipmaps = false
    canvas.minFilter = THREE.NearestFilter
    canvas.magFilter = THREE.NearestFilter
    updateCanvas(canvas)
  }, [])

  // render ------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <ambientLight />
      <Controls />
      <DragControl onDragEnd={updatePoint} />
      <ambientLight intensity={0.3} position={[0, 0, 300]} />

      {/* Map */}
      { context.mapFeed &&
        <mesh visible name="map" position={context.mapFeed.position} rotation={context.mapFeed.rotation} scale={context.mapFeed.scale} onClick={mouseUp}>
          <planeBufferGeometry attach="geometry" args={[1, 1, 0]} />
          <meshBasicMaterial attach="material" transparent map={context.mapFeed.canvas} />
        </mesh>
      }

      {/* Test Map */}
      {/* <mesh visible name="map" position={[0, 0, 0]} rotation={[0, 0, 0]} onClick={mouseUp} scale={[5, 5, 5]}>
        <sphereGeometry attach="geometry" args={[1, 16, 16]} />
        <meshStandardMaterial attach="material" color="white" transparent opacity={0.6} roughness={1} metalness={0} />
      </mesh> */}

      {/* Nav Points */}
      { context.mapObjects.points && context.mapObjects.points.map((point, i) => ( 
          <Point key={i} color='#1b56ed' position={point.point} name={point.counter} />
      ))}

      {/* Edges */}
      { edges && edges.map((item, i) => (
          <line key={i} position={[0, 0, 1]} frustumCulled={false} name={item.name}>
            <bufferGeometry attach="geometry" onUpdate={onUpdate(item && item.points)} />
            <lineBasicMaterial attach="material" color={'#9ea5c4'} linewidth={3} linecap={'round'} linejoin={'round'} />
          </line>
      ))}

      {/* Area Points */}
      { context.mapObjects.areas && context.mapObjects.areas.map(item => Array.isArray(item.polygon) && item.polygon.map((p, i) => (p && 
          <Point key={i} color='#9ea5c4' position={p.point} name={p.counter} />
      )))}

      {/* Area Edges */}
      { context.mapObjects.areas && context.mapObjects.areas.map((item, i) => item && item.polygon &&
          <line key={i} position={[0, 0, 1]} frustumCulled={false} name={"z" + String(i)}>
            <bufferGeometry attach="geometry" onUpdate={onUpdate(item && item.polygon)} />
            <lineBasicMaterial attach="material" color={'#9ea5c4'} linewidth={3} linecap={'round'} linejoin={'round'} />
          </line>
      )}

      {/* No_go Points */}
      { context.mapObjects.no_go && context.mapObjects.no_go.map(item => Array.isArray(item.polygon) && item.polygon.map((p, i) => (p && 
          <Point key={i} color='#d1507e' position={p.point} name={p.counter} />
      )))}

      {/* No_go Edges */}
      { context.mapObjects.no_go && context.mapObjects.no_go.map((item, i) => item && item.polygon &&
          <line key={i} position={[0, 0, 1]} frustumCulled={false}>
            <bufferGeometry attach="geometry" onUpdate={onUpdate(item && item.polygon)} />
            <lineBasicMaterial attach="material" color={'#d1507e'} linewidth={3} linecap={'round'} linejoin={'round'} />
          </line>
      )}

      {/* Robot position */}
      { robotPos && 
        <Point color='orange' position={robotPos} name={"robot"} />
      }
    </>
  )
}
