import React from "react"

const ModalArea = (props) => 
{
  return (
    <div className="controls">
      <div className="control" style={{ marginBottom: "1rem" }}>
        <label>Max. Velocity: </label>
        <input
          type='number'
          value={props.velocity ? props.velocity : ''}
          autoFocus
          onChange={(e) => props.update("velocity", e.target.value)}
          placeholder='0.0'
          min='0'
          step='0.1'
          title='Velocity'
        />
      </div>
      <div className="control" style={{ marginBottom: "1rem" }}>
        <label>Beep: </label>
        <input
          type="checkbox"
          id="beep"
          className="checkbox"
          checked={props && props.beep ? true : false}
          onChange={(e) => props.update("beep", e.target.checked)}
        />
      </div>
    </div>
  )
}

export default ModalArea
