import React, { Component } from "react"
import Context from "./Context"

export default class ContextProvider extends Component {
  state = {
    loggedIn: false,
    apiToken: null,
    user: null,
    availableRobots: [],
    scanRobots: false,
    availableMissions: localStorage.getItem("availableMissions") ?
      JSON.parse(localStorage.getItem("availableMissions"))
      : [],
    availablePoints: [],
    selectedRobot: null,
    typeRobot: null,
    pointNames: [],
    drawTools: [
      { _id: 'points', name: 'Navigation' },
      { _id: 'areas', name: 'Areas' },
      { _id: 'no_go', name: 'Forbidden' },
      { _id: 'location', name: 'Here' }
    ],
    selectedUser: null,
    actionValueTypes: [{ name: "value" }, { name: "station" }],
    robotsValueTypes: [{ name: "UV-Robot" }, { name: "FI-250" }, { name: "Generic" }],
    battery: null,
    availableActions: [
      { id: "go-to", name: "go-to", needsValue: true, valueType: "point" },
      { id: "go-to-line-follow",  name: "go-to-line-follow", needsValue: true, valueType: "point"},
      { id: "stop", name: "stop", needsValue: false },      
      { id: "follow", name: "follow", needsValue: false },
      { id: "pause", name: "pause", needsValue: true, valueType: "value" },
      { id: "rotate", name: "rotate", needsValue: true, valueType: "value" },
      { id: "select-map", name: "select-map", needsValue: true, valueType: "mapList" },
      { id: "desinfect", name: "desinfect", needsValue: true, valueType: "onOff" },
      { id: "desinfect-uv", name: "desinfect-uv", needsValue: true, valueType: "value" },
      { id: "desinfect-all", name: "desinfect-all", needsValue: true, valueType: "value" },
      { id: "timer", name: "timer", needsValue: true, valueType: "value" },
      { id: "loop", name: "loop", needsValue: true, valueType: "startEnd" },
      { id: "dock", name: "dock", needsValue: true, valueType: "docking" },
      { id: "release-magnet",  name: "release-magnet", needsValue: false},
      { id: "lifter", name: "lifter", needsValue: true, valueType: "upDown" },
      { id: "check-orientation", name: "check-orientation", needsValue: false },
      { id: "orientate-to", name: "orientate-to", needsValue: true, valueType: "point" },
      { id: "orientate-to-drop", name: "orientate-to-drop", needsValue: true, valueType: "point" },
      { id: "orientate-to-charge", name: "orientate-to-charge", needsValue: true, valueType: "point" },
      { id: "undock-pallet", name: "undock-pallet", needsValue: false },  
      { id: "undock-trolley", name: "undock-trolley", needsValue: false },  
      { id: "orientate-to-fork", name: "orientate-to-fork", needsValue: true, valueType: "point" },
      { id: "fork", name: "fork", needsValue: true, valueType: "value" },
      { id: "release-charger",  name: "release-charger", needsValue: false},
      { id: "bench-control", name: "bench-control", needsValue: true, valueType: "upDown" },
      { id: "publish-pose", name: "publish-pose", needsValue: false , value: "Base"},


      
      
      /*missão: "undock-pallet" - sem valor
      missão: "orientate-to-fork" - valor: pontos do mapa, como se fosse um "go-to"
      missão: "fork" - valor: float, de 0 a 3*/

    ],
    availableSubActions: [
      { name: "onOff", values: ["on", "off"] },
      { name: "startEnd", values: ["start", "end"] },
      { name: "docking", values: ["charger", "pallet", "qrCode", "trolley","magnet"]},
      { name: "upDown", values: ["up", "down"] }
    ],

    firstLoad: true,
    modalState: { missions: false, maps: false, configuration: false, settingsRemote: false, import: false, dashboard: false, dashboard_status: false, disconnect: false },
    importExcel: localStorage.getItem("importExcel") ? JSON.parse(localStorage.getItem("importExcel")) : [],
    robotConfiguration: localStorage.getItem("robotConfiguration") ? JSON.parse(localStorage.getItem("robotConfiguration")) : [],
    isRobotConnected: false,
    destinations: localStorage.getItem("destinations") ? JSON.parse(localStorage.getItem("destinations")) : [],
    // mapIndex: 0,
    mapIndex: localStorage.getItem("mapIndex") ? JSON.parse(localStorage.getItem("mapIndex")) : 0,
    // maps: [],
    maps: localStorage.getItem("maps") ? JSON.parse(localStorage.getItem("maps")) : [],
    mapFeed: null,
    mapOrientation: localStorage.getItem("mapOrientation") ? JSON.parse(localStorage.getItem("mapOrientation")) : {},
    currentMission: null,
    foundPeople: false,
    settings: localStorage.getItem("settings") ? JSON.parse(localStorage.getItem("settings")) : {},
    endOfMission: false,
    orderStatus: null,
    robotInfo: {},
    message: "",
    errorMessage: {},
    mappingCurrent: localStorage.getItem("mappingCurrent") ? JSON.parse(localStorage.getItem("mappingCurrent")) : null,
    mappingStatus: false,
    robotStatus: false,
    selected: {},
    mapObjects: {},
    pointCounter: localStorage.getItem("pointCounter") ? JSON.parse(localStorage.getItem("pointCounter")) : 0,
    helpers: { newItem: true },
    savingStatus: false,
    loadingMap: false,
    loadingPoints: false,
    orientation: false,
    acceleratorValue: 50,
    joystick: 0,
    robotPosition: null,
    diagnosticSwitch: localStorage.getItem("diagnosticSwitch") ? JSON.parse(localStorage.getItem("diagnosticSwitch")) : false,
    diagnostic: "",
    diagnosticTime: new Date()
  }

  actions = {
    setApiToken: (token) => {
      this.setState({ apiToken: token })
      console.log("connection token updated")
    },

    setBattery: (percentage) => {
      this.setState({ battery: percentage })
    },

    setAvailableRobots: (robots) => {
      this.setState({ availableRobots: robots })
    },

    setSelectedRobot: (robot) => {
      this.setState({ selectedRobot: robot })
      // console.log("context: selecting robot", robot)
    },
    setRobotPosition: (position) => {
      if (this.validatePostion(this.state.robotPosition, position)) {
        this.setState({ robotPosition: position })
      }
    },
    setDiagnostic: (diagnostic) => {
      // if (this.validatePostion(this.state.robotPosition,position)) {
      this.setState({ diagnostic: diagnostic })
      // }
    },
    setDiagnosticTime: (diagnosticTime) => {
      // if (this.validatePostion(this.state.robotPosition,position)) {
      this.setState({ diagnosticTime: diagnosticTime })
      // }
    },
    setSelectedTypeRobot: (typeRobot) => {
      this.setState({ typeRobot: typeRobot })
      // console.log("context: selecting Type robot", typeRobot)
    },

    setUser: (user) => {
      this.setState({ user: user })
      // console.log("context: setting user to ", user)
    },

    setSelectedUser: (userId) => {
      this.setState({ selectedUser: userId })
      // console.log("context: setting selectedUser to ", userId)
    },

    setAvailableMissions: (missions) => {
      this.setState({ availableMissions: missions })
      // console.log("context: setting available missions", missions)
    },
    setRobotConfiguration: (robot) => {
      this.setState({ robotConfiguration: robot })
      // console.log("context: setting Configuration by new Robots IP", robot)
    },

    setImportExcel: (importExcel) => {
      this.setState({ importExcel: importExcel })
      // console.log("context: setting import excel", importExcel)
    },

    setAvailablePoints: (points) => {
      this.setState({ availablePoints: points })
      // console.log("context: setting available points", points)
    },
    setSavingStatus: (val) => this.setState({ savingStatus: val }),

    setLoadingMap: (val) => this.setState({ loadingMap: val }),

    setLoadingPoints: (val) => this.setState({ loadingPoints: val }),


    setOrientation: (val) => this.setState({ orientation: val }),

    setAcceleratorValue: (val) => this.setState({ acceleratorValue: val }),

    setJoystick: (val) => this.setState({ joystick: val }),

    setPointNames: (names) => this.setState({ pointNames: names }),

    setFirstLoad: () => this.setState({ firstLoad: false }),

    setModalState: (mState) => this.setState({ modalState: mState }),

    setIsRobotConnected: (val) => this.setState({ isRobotConnected: val }),

    setScanRobots: (scan) => this.setState({ scanRobots: scan }),

    setMapIndex: (val) => {
      // console.log("context setting map index", val)
      this.setState({ mapIndex: val })
    },
    setMapOrientation: (val) => {
      this.setState({ mapOrientation: val })
    },
    

    setMapFeed: (val) => this.setState({ mapFeed: val }),

    setDestinations: (val) => {
      if (this.state.destinations.indexOf(val) < 0) this.setState({ destinations: [...this.state.destinations, val] })
    },

    setCurrentMission: (val) => this.setState({ currentMission: val }),

    setOrderStatus: (val) => this.setState({ orderStatus: val }),

    setFoundPeople: (val) => this.setState({ foundPeople: val }),

    setSettings: (val) => this.setState({ settings: val }),

    setErrorMessage: (val) => this.setState({ errorMessage: val }),

    setMessage: (val) => this.setState({ message: val }),

    setEndOfMission: (val) => {

      let update = {}


      if (val?.status === "executing" && Array.isArray(val?.actions) && val?.actions[0].name === "GotoMachine") {
        if (!this.state.orderStatus) this.actions.setOrderStatus(true)
      } else {
        if (this.state.orderStatus) this.actions.setOrderStatus(false)
      }



      // end of mission
      if (this.state.currentMission && (val?.status === "cancelled" || val?.status === "finished")) {
        if (!this.state.endOfMission) update.endOfMission = true
      } else {
        if (this.state.endOfMission) update.endOfMission = false
      }

      //Loading map
      if (val?.status === "executing" && val?.actions[0]?.name === "SelectMapMachine") {
        if (!this.state.loadingMap) this.actions.setLoadingMap(true)
        if (!this.state.loadingPoints) this.actions.setLoadingPoints(true)

      } else {
        if (this.state.loadingMap) this.actions.setLoadingMap(false)
        if (this.state.loadingPoints) this.actions.setLoadingPoints(false)
      }

      // watch mapping
      if (val?.status === "executing" && val?.actions[0]?.name === "MappingMachine") {
        if (val.actions[0].status === "savingMapStatus") this.actions.setSavingStatus(true)
        if (!this.state.mappingStatus) update.mappingStatus = true

      } else {
        if (this.state.savingStatus) this.actions.setSavingStatus(false)
        if (this.state.mappingStatus) update.mappingStatus = false

      }

      // watch blocks
      if (val?.status === "executing" && val?.actions[0]?.status === "blockedStatus") {
        if (!this.state.robotStatus) {
          update.robotStatus = true
          update.modalState = { ...this.state.modalState, disconnect: true }
        }
      } else {
        if (this.state.robotStatus) {
          update.robotStatus = false
          update.modalState = { ...this.state.modalState, disconnect: false }
        }
      }

      if (update.endOfMission || update.mappingStatus || update.robotStatus || update.modalState) {
        // console.log("updating #########", update)
        this.setState(update)
      }
    },

    setMappingStatus: (val) => {
      if (val?.status !== this.state.mappingStatus?.status) {
        this.setState({ mappingStatus: val })
      }
    },

    setRobotStatus: (val) => {
      // console.log("setting robot status", val)
      if (val !== this.state.robotStatus) {
        this.setState({ robotStatus: val })
      }
    },

    setMaps: (val) => {
      // console.log("context setting maps", val)
      this.setState({ maps: val })
    },

    setMappingCurrent: (val) => {
      this.setState({ mappingCurrent: val })
    },

    setDiagnosticSwitch: (val) => {
      this.setState({ diagnosticSwitch: val })
    },

    setRobotInfo: (val) => this.setState({ robotInfo: val }),

    setSelected: (val) => this.setState({ selected: { ...this.state.selected, ...val } }),

    setMapObjects: (val) => this.setState({ mapObjects: val }),

    setPointCounter: (val) => this.setState({ pointCounter: val }),

    setHelpers: (val) => this.setState({ helpers: val })
  }


  validatePostion(lastPostion, newPostion) {
    if (lastPostion?.position?.x.toFixed(4) != newPostion?.position?.x.toFixed(4)
      || lastPostion?.position?.y.toFixed(4) != newPostion?.position?.y.toFixed(4)
      || lastPostion?.position?.z.toFixed(4) != newPostion?.position?.z.toFixed(4)) {
      return true
    } else {
      return false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.apiToken !== prevState.apiToken)
      localStorage.setItem("apiToken", JSON.stringify(this.state.apiToken))
    if (this.state.user !== prevState.user)
      localStorage.setItem("user", JSON.stringify(this.state.user))
    if (this.state.selectedRobot !== prevState.selectedRobot)
      localStorage.setItem("selectedRobot", JSON.stringify(this.state.selectedRobot))
    if (this.state.availableMissions !== prevState.availableMissions)
      localStorage.setItem("availableMissions", JSON.stringify(this.state.availableMissions))
    if (this.state.destinations !== prevState.destinations)
      localStorage.setItem("destinations", JSON.stringify(this.state.destinations))
    if (this.state.maps !== prevState.maps)
      localStorage.setItem("maps", JSON.stringify(this.state.maps))
    if (this.state.mapIndex !== prevState.mapIndex)
      localStorage.setItem("mapIndex", JSON.stringify(this.state.mapIndex))
    if (this.state.mapOrientation !== prevState.mapOrientation)
      localStorage.setItem("mapOrientation", JSON.stringify(this.state.mapOrientation))
    if (this.state.robotConfiguration !== prevState.robotConfiguration)
      localStorage.setItem("robotConfiguration", JSON.stringify(this.state.robotConfiguration))
    if (this.state.importExcel !== prevState.importExcel)
      localStorage.setItem("importExcel", JSON.stringify(this.state.importExcel))
    if (this.state.settings !== prevState.settings)
      localStorage.setItem("settings", JSON.stringify(this.state.settings))
    if (this.state.errorMessage !== prevState.errorMessage)
      localStorage.setItem("errorMessage", JSON.stringify(this.state.errorMessage))
    if (this.state.message !== prevState.message)
      localStorage.setItem("message", JSON.stringify(this.state.message))
    if (this.state.mappingCurrent !== prevState.mappingCurrent)
      localStorage.setItem("mappingCurrent", JSON.stringify(this.state.mappingCurrent))
    if (this.state.diagnosticSwitch !== prevState.diagnosticSwitch)
      localStorage.setItem("diagnosticSwitch", JSON.stringify(this.state.diagnosticSwitch))
    if (this.state.pointCounter !== prevState.pointCounter)
      localStorage.setItem("pointCounter", JSON.stringify(this.state.pointCounter))
  }

  render() {
    const context = { ...this.state, actions: this.actions }
    return (
      <Context.Provider value={context} loggedIn={context.loggedIn}>
        {this.props.children}
      </Context.Provider>
    )
  }
}
