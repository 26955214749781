import React, { useContext, useEffect } from "react"
import { watchDiagnostic } from "../utils/ROS/ROSFunctions"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Context from "../utils/Context"
import Time from '../components/elements/Time'

export default function DiagnosticStatus() {

    const context = useContext(Context)

    useEffect(() => {
        const topic = watchDiagnostic(context)
        return (() => topic?.unsubscribe())
    }, [])

    useEffect(() => {
        if (new Time().dateDiffBySeconds(context.diagnosticTime , new Date()) > 10) {
            context.actions.setDiagnostic(null)
        }
    }, [context.modalState])

    const openModal = () => {
        if (new Time().dateDiffBySeconds(context.diagnosticTime , new Date()) > 10) {
            context.actions.setDiagnostic(null)
        }else{
           context.actions.setModalState({ ...context.modalState, disconnect: true })
        }
    }

    return (
        <div className="column">
            <p className="pt-2">
                <span className={context.diagnostic ? "icon has-text-danger " : ""}  >
                    <FontAwesomeIcon icon={faInfoCircle} className="fas icon is-medium" onClick={openModal} />
                </span>
            </p>
        </div>

    )
}