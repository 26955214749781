import React, { useState, useContext, useRef, Suspense, useEffect, useCallback } from "react"
import { Canvas } from "react-three-fiber"
import { Map } from "./Map/Map"
import ModalPoint from "./Map/ModalPoint"
import ModalEdge from "./Map/ModalEdge"
import ModalReset from "./Map/ModalReset"
import ModalArea from "./Map/ModalArea"
import ModalMap from "./Map/ModalMap"
import ModalDelete from "./Map/ModalDelete"
import Context from "../../utils/Context"
import { sendTaskToRobot, cancelActions, sendNavInfo, watchRobotPosition } from "../../utils/ROS/ROSFunctions"
import Joystick from "../../components/Joystick"
import OccupancyGridVisualizer from "./Map/OccupancyGridVisualizer"
import ROS from "../../utils/ROS/ROS"
import api from "../../api/api"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave, faSpinner, faLocationArrow, faUndo, faRetweet, faMapMarked, faCheck, faTrash, faBan, faVectorSquare, faReply, faMapPin, faMapSigns } from "@fortawesome/free-solid-svg-icons"
import ShowMessage from "../../components/ShowMessage"
import CompareArray from "../../components/elements/CompareArray"

export default function Maps() {
  const context = useContext(Context)
  const canvasRef = useRef()
  const modalRef = useRef()
  const orientation = context.orientation
  const [mapping, setMapping] = useState((context.mappingCurrent) ? context.mappingCurrent : [])
  const [modal, setModal] = useState(false)
  const [typeMap, setTypeMap] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [isMapping, setIsMapping] = useState(false)
  const [hand, setHand] = useState(false)
  const [canvas, setCanvas] = useState(null)

  const c = console.log.bind(document)

  // process mapping feeback --------------------------------------

  useEffect(() => {
    if (context.mappingStatus) {
      setMapping((context.mappingCurrent) ? context.mappingCurrent : mapping)
      setIsMapping((context.mappingCurrent) ? context.mappingCurrent.mapping : false)
    }

  }, [context.mappingStatus])


  useEffect(() => {
    let topic

    topic = watchRobotPosition(context)

    return (() => { if (topic) topic.unsubscribe() })
  }, [context.isRobotConnected])


  // validate nav point name ----------------------------------------------------------------------------

  const savePoint = async () => {
    if (context.selected.tool?._id === 'points') {
      const filtered = context.mapObjects.points?.filter(p => p.name?.indexOf(n => n === modalData.name) > -1)
      console.log('finding point name', filtered, context.mapObjects.points, modalData?.name)

      if (!modalData?.name || (modalData?.name && modalData.name.length < 0)) {
        context.actions.setMessage({ message: "Please add an ID", type: "error" })
        return false

      } else if (filtered.length > 1) {
        context.actions.setMessage({ message: "This ID is already in use", type: "error" })
        return false
      }
    }

    // reset newObject
    if (context.helpers.newObject) context.actions.setHelpers({ ...context.helpers, newObject: false })

    setModalData(null)

    setModal(false)
    context.actions.setMessage({ message: "There are unsaved items", type: "error" })
  }

  // open modals -----------------------------------------------------------

  const openModal = (modalType, data) => {
    if (data) setModalData(data)

    if (modalType) setModal(modalType)

  }

  const locateRobot = () => {
    // (context)
    selectTool('location')
    setModal('location')
  }

  // convert nav info format -----------------------------------------------

  const convertNavInfo = useCallback(() => {

    let navInfo = { points: [], edges: [], zones: [], no_go: [], map_name: context.selected.map?.name }

    // console.log("filling nav info", context.mapObjects)
    console.log("nav info map name", context.selected.map?.name)

    // fill points info
    Array.isArray(context.mapObjects.points) && context.mapObjects.points.forEach(point => {
      navInfo.points.push({ id: point.name, point: point.point })
    })

    // fill edges info
    let fromPoint, toPoint
    Array.isArray(context.mapObjects.edges) && Array.isArray(context.mapObjects.points) && context.mapObjects.edges.forEach(item => {
      fromPoint = context.mapObjects.points.find(p => p.name.indexOf(item.from) > -1)
      toPoint = context.mapObjects.points.find(p => p.name.indexOf(item.to) > -1)
      if (fromPoint?.point) fromPoint = fromPoint.point
      if (toPoint?.point) toPoint = toPoint.point
      if (fromPoint && toPoint) {
        const distance = Math.sqrt(Math.pow((toPoint.x - fromPoint.x), 2) + Math.pow((toPoint.y - fromPoint.y), 2))
        navInfo.edges.push({ id1: item.from, id2: item.to, distance: distance, avoid: item.avoid, reversable: item.reversable })
      }
    })

    // fill zones info
    let zone
    Array.isArray(context.mapObjects.areas) && context.mapObjects.areas.forEach(item => {
      zone = { polygon: [], max_velocity: item.velocity ? item.velocity : false, beep: item.beep ? item.beep : false }
      Array.isArray(item.polygon) && item.polygon.forEach((point, i) => {
        if (i < item.polygon.length)
          zone.polygon.push(point.point)
      })
      navInfo.zones.push(zone)
    })

    // fill no_go info
    Array.isArray(context.mapObjects.no_go) && context.mapObjects.no_go.forEach(item => {
      zone = { polygon: [] }
      Array.isArray(item.polygon) && item.polygon.forEach(point => {
        zone.polygon.push(point.point)
      })
      navInfo.no_go.push(zone)
    })

    return navInfo
  }, [context.selected])

  // save map --------------------------------------------------------------

  const save = () => {
    let maps = [...context.maps]
    validateArea()
    const newMap = { ...context.maps[context.mapIndex], objects: context.mapObjects }
    maps[context.mapIndex] = newMap
    context.actions.setMaps(maps)


    sendNavInfo(convertNavInfo())
    context.actions.setMessage({ message: "Saved", type: "success" })
  }

  // save map name ----------------------------------------------------------

  const saveMap = async () => {

    if (!modalData?.mapName || modalData?.mapName === "") {
      context.actions.setMessage({ message: "Please fill map name", type: "error" })
      return false
    }

    const searchMap = context.maps.find(m => m.name?.toLowerCase() === modalData.mapName?.toLowerCase() && m.robot === context.selectedRobot?.name)
    if (searchMap) {
      context.actions.setMessage({ message: "Map already exists", type: "error" })
      return false
    }

    if (~modalData?.mapName.indexOf('.') || ~modalData?.mapName.indexOf('/') || ~modalData?.mapName.indexOf('\\') || ~modalData?.mapName.indexOf(';') || ~modalData?.mapName.indexOf(':')) {
      context.actions.setMessage({ message: "Please not fill map name with points, slash or backslash", type: "error" })
      return false
    }


    context.actions.setMapObjects({})

    const mapIndex = context.maps.length
    context.actions.setMapIndex(mapIndex)
    let maps = context.maps.map(m => m.robot === context.selectedRobot?.name ? ({ ...m, active: false }) : m)
    let mapId = null

    // send map item to FMS
    if (context.settings.fmsConnection) {

      if (!context.robotInfo) {
        context.actions.setMessage({ message: "Robot not registered on FMS. Please register it with the official name.", type: "error" })
        return false
      }

    }

    context.actions.setMaps([...maps, { name: modalData.mapName, robot: context.selectedRobot.name, robotId: context.selectedRobot._id, active: true, _id: mapId }])
    context.actions.setMappingCurrent({ name: modalData.mapName, robot: context.selectedRobot.name, robotId: context.robotInfo._id, mapping: isMapping })
    sendTaskToRobot({ actions: [{ id: typeMap, name: typeMap, value: modalData.mapName }], id: typeMap, name: typeMap })
    context.actions.setSelected({ map: { name: modalData.name, robotId: context.robotInfo._id } })
    setModal(null)

    // subscribe to map
    if (canvas) {
      console.log("subscribing to map")
      window.mapSubscriber = ROS.subscribeMap(callback, canvas, context, false)
    }
  }


  const getMapOccurrence = (maps, value) => {
    var count = maps.filter((e) => (e.name === value && e.robot === context.robotInfo.name)).length
    var mapsPosition
    var qtd = []
    

    if (count > 1) {
      let newMaps = maps
    
      newMaps.splice(newMaps.indexOf(value))
      context.actions.setMaps(newMaps)

    } else {
      return true
    }

  }

  // sync fms --------------------------------------------------------------

  const exportMaps = useCallback(async () => {

    if (context.diagnosticSwitch)
      c("DBug: exportMaps - map selected ", context.selected)

    if (!context.robotInfo) {

      context.actions.setMessage({ message: "Robot not registered on FMS. Please register it with the official name.", type: "error" })
      return false
    }

    if (context.settings.fmsConnection) {


      getMapOccurrence(context.maps, context.selected.map.name)

      var mapId, respMap

      var index = context.maps.findIndex((e) => e.name === context.selected?.map?.name && e.robot === context.robotInfo.name)

      if (index || index === 0) {
        if (!context.maps[index]?._id) {


          //NEW MAP

          if (context.diagnosticSwitch)
            c("ExportMaps - New Map: ", "name: ", context.selected.map.name, " owner: ", context.robotInfo.owner, "robotId:", context.robotInfo._id, "areaId: ", context.robotInfo.areaId, "origin: ", context.mapOrientation, "objects: ", context.mapObjects)

          try {

            respMap = await api.saveMap({ name: context.selected.map.name, owner: context.robotInfo.owner, robotId: context.robotInfo._id, areaId: context.robotInfo.areaId, origin: context.mapOrientation, objects: context.mapObjects })

            c("** RespMAP ", respMap)
            if (!respMap || respMap.status === 401) {
              context.actions.setMessage({ message: "Error to export, try again or reconnect to login", type: "error" })
              return false
            }

            mapId = respMap.data._id

            if (context.diagnosticSwitch)
              c("ExportMaps - New Map: ", "New Index: ", mapId)

            if (respMap) context.actions.setMessage({ message: "Export to success", type: "success" })

          } catch (error) {
            context.actions.setMessage({ message: "Error to export", type: "error" })
            console.log("Error", error)
          }

          if (mapId) {
            //let newMaps = context.maps.splice(index, 1, Object.assign({}, context.maps[index], { _id: mapId }));
            if (context.diagnosticSwitch)
              c("ExportMaps - New Map: ", "Add ID: ", mapId)
            let newMaps = [...context.maps]

            if (context.diagnosticSwitch)
              c("ExportMaps - New Map: ", "Map array: ", newMaps)

            newMaps[index]._id = mapId

            if (context.diagnosticSwitch)
              c("ExportMaps - New Map: ", "Map with ID: ", newMaps, " -  Map Update ID: ", newMaps[index]._id)

            context.actions.setMaps(newMaps)
          } else {
            context.actions.setMessage({ message: "No map ID found on FMS", type: "error" })
          }


        } else {

          //UPDATE MAP
          if (context.diagnosticSwitch)
            c("ExportMaps - Update map: name befour filtered: ", context.maps[index])


          let filteredMap = context.maps[index]
          delete filteredMap.active
          filteredMap.syncFMS = true
          // console.log("updating FMS with map", filteredMap)

          if (context.diagnosticSwitch)
            c("ExportMaps - Update map: map Object to update : ", filteredMap)

          let updateResult = await api.updateMap(filteredMap)
          if (updateResult) context.actions.setMessage({ message: "map update on FMS result", type: "success" })
          else context.actions.setMessage({ message: "map or points not updated on FMS", type: "error" })
          if (context.diagnosticSwitch)
            c("ExportMaps - Update map: Map update on FMS result: ", updateResult)
        }
      } else {
        context.actions.setMessage({ message: "Map not saved, please save gu", type: "error" })
        return false
      }

    } else {

      context.actions.setMessage({ message: "Error: fms Connection is not available, please verify in Settings", type: "error" })
      return false

    }

  }, [context.selected])

  // improve map ----------------------------------------------------

  const saveMapImprove = async () => {

    setModal(null)

    if (!context.selected.map) {
      setModalData(false)
      cancelActions({ stopMapping: true })
      context.actions.setSavingStatus(true)
      setIsMapping(!isMapping)
      setTypeMap(false)
      context.actions.setMessage({ message: "Please select available map", type: "error" })
      return false
    }

    //context.actions.setMaps([...maps, { name:  context.selected.map?.name, robot: context.selectedRobot.name, active: true, _id: mapId }])
    context.actions.setMappingCurrent({ name: context.selected.map?.name, robot: context.selectedRobot.name, mapping: isMapping, robotId: context.selectedRobot._id })
    sendTaskToRobot({ actions: [{ id: typeMap, name: typeMap, value: context.selected.map?.name }], id: typeMap, name: typeMap })
    //context.actions.setSelected({ map: { name: context.selected.map?.name } })

    // subscribe to map
    if (canvas) {
      // console.log("subscribing to map")
      window.mapSubscriber = ROS.subscribeMap(callback, canvas, context, false)
    }
  }

  // select map -----------------------------------------------------

  const selectMap = async (event) => {
    const index = context.maps.findIndex(m => m.name === event.target.value)
    const map = context.maps[index]
    context.actions.setMapIndex(index)
    context.actions.setSelected({ map: { name: event.target.value } })
    context.actions.setHelpers({ ...context.helpers, newItem: true })
    setIsMapping((context.mappingCurrent && context.mappingCurrent.name === event.target.value) ? context.mappingCurrent?.mapping : false)
    context.actions.setMapObjects(map.objects ? map.objects : {})

    let maps = context.maps.map(m => m.robot === context.selectedRobot?.name && m.name === event.target.value ?
      ({ ...m, active: true })
      : m.robot === context.selectedRobot?.name ?
        ({ ...m, active: false })
        :
        (m))
    context.actions.setMaps(maps)

    sendTaskToRobot({ actions: [{ id: "select-map", name: "select-map", value: event.target.value }], id: "select-map", name: "select-map" })

    // console.log("fms getting map info", context.settings.fmsConnection, map._id)
    // fms sync
    if (context.settings.fmsConnection) {//&& map._id) {
      // update default map on area item on FMS (site and area for the robot must be defined)
      // console.log("updating area map", context.robotInfo.areaId, map._id)

      //#api.updateAreaMap(context.robotInfo.areaId, map._id)

      // retrieve map nav info from fms
      // console.log("selected map target 1", event.target.value)
      //const updatedMap = await api.getMap(event.target.value)
      const mapsByFMS = await api.fetchMaps()
      // console.log("updated map", updatedMap)

      var mapsApi = mapsByFMS.data
      var mapLocal = context.maps

      /****************/
      const sameMap = (mapsApi, mapLocal) => mapsApi.name === mapLocal.name && mapsApi.robot === mapLocal.robot;

      var mapsApiDiff = new CompareArray().compareArrays(mapsApi, mapLocal, sameMap);
      var mapLocalDiff = new CompareArray().compareArrays(mapLocal, mapsApi, sameMap);

      var result = [...mapsApiDiff, ...mapLocalDiff];

      console.log("--", result)

      /****************/

      //Rollback this value
      /*
            if (Array.isArray(updatedMap.data) && updatedMap.data[0].objects) {
              const newMaps = [...context.maps]
              newMaps[index].objects = updatedMap.data[0].objects
              console.log("retrieved map nav info from fms", updatedMap.data[0].objects)
              
              context.actions.setMaps(newMaps)
            }
            */
    }
  }

  // reset map --------------------------------------------------------------

  const resetMap = () => {
    let newMap = { ...context.maps[context.mapIndex] }
    newMap.objects = {}
    let newMaps = [...context.maps]
    newMaps[context.mapIndex] = newMap
    context.actions.setMapObjects({})
    context.actions.setMaps(newMaps)
    context.actions.setSelected({ map: { name: newMap } })
    setModal(false)

    // update map on FMS
    /* if (context.settings.fmsConnection && newMap._id) {
       api.updateMap(newMap)
     }*/
  }

  // update modal data ------------------------------------------------

  const updateModalData = (item, value) => {
    const data = { ...modalData, [item]: value }
    setModalData(data)

    // update point label and area max velocity and beep
    let newObjects = { ...context.mapObjects }

    if (!context.selected.mapObject) return false

    switch (context.selected.mapObject.type) {
      case 'points':
        //  console.log("updating point", context.selected.connect)
        let currentPointName = newObjects.points[context.selected.mapObject.idx].name

        // update existing edges related to the point
        context.mapObjects.edges && context.mapObjects.edges.forEach((e, i) => {
          if (e.from === currentPointName) {
            // console.log("updating edge point from", i, currentPointName, data.name)
            newObjects.edges[i].from = data.name
          }
          if (e.to === currentPointName) {
            //  console.log("updating edge point to", i, currentPointName, data.name)
            newObjects.edges[i].to = data.name
          }
        })

        // update point name
        newObjects.points[context.selected.mapObject.idx].name = data.name
        break
      case 'edges':
        if (context.selected.connect && newObjects.edges) { // edge update
          //  console.log("updating edge", data)
          newObjects.edges[context.selected.mapObject.idx].reversable = data.reversable
          newObjects.edges[context.selected.mapObject.idx].avoid = data.avoid
          break
        }
        break
      case 'areas':
        // console.log("updating area", context.selected.mapObject.idx, data)
        newObjects.areas[context.selected.mapObject.idx].velocity = parseFloat(data.velocity)
        newObjects.areas[context.selected.mapObject.idx].beep = data.beep
        break
      default:
    }

    context.actions.setMapObjects(newObjects)
  }

  // closeModal ------------------------------------------------------------

  const closeModal = () => {
    setModal(false)
    setModalData(false)
    setIsMapping(false)
    setTypeMap(false)

    if (!modalData?.name && (modal === 'points' || modal === 'areas')) undo()

    context.actions.setSelected({ mapObject: null })
    context.actions.setHelpers({ ...context.helpers, editing: false })
  }

  // change hand side -----------------------------------------------------

  const handSelected = () => {
    setHand(!hand)
  }

  // change hand on orientation ------------------------------------------
  /*
    useEffect(() => {
      (orientation) ? setHand(true) : setHand(false)
    }, [orientation])*/

  // toggle mapping ------------------------------------------------------

  const toggleMapping = (typeModal) => {
    if (isMapping) {
      cancelActions({ stopMapping: true })
      context.actions.setSavingStatus(true)
      context.actions.setSelected({ map: { name: context.mappingCurrent.name, robotId: context.robotInfo._id } })
      context.actions.setMappingCurrent(null)
      setModalData(null)
      setHand(false)
      setTypeMap(false)
      // console.log("preparing to shut down map subscriber")
      if (window.mapSubscriber) {
        // console.log("shutting down map subscriber")
        window.mapSubscriber.shutdown()
      }
    } else {
      openModal(typeModal)
      setTypeMap(typeModal)
    }

    setIsMapping(!isMapping)
  }

  // delete map ----------------------------------------------------------

  const deleteMap = () => {

    const indx = context.maps.findIndex(v => v.name === context.selected.map?.name && v.robot === context.selectedRobot.name)
    if (indx < 0) {
      setModal(false)
      return false
    }
    const maps = [...context.maps]
    maps.splice(indx, 1)
    context.actions.setMaps(maps)

    //context.actions.setSelected({ map: context.maps[0]?.name ? context.maps[0].name  :  null})
    context.actions.setSelected({ map: { name: "Select map" } })
    setModal(false)
  }

  // Processing image callback --------------------------------------------

  const callback = (msg, canvas, context, firstRun) => {
    OccupancyGridVisualizer.drawMap(msg, canvas.image)
    let header = msg.header
    canvas.needsUpdate = true
    let width = msg.info.width * msg.info.resolution
    let height = msg.info.height * msg.info.resolution
    let scale = [width, height, 1]
    let position = [(msg.info.origin.position.x + width / 2), (msg.info.origin.position.y + height / 2), 0]
    context.actions.setMapFeed({ canvas: canvas, header: header, scale: scale, position: position })
    context.actions.setMapOrientation({ x: parseFloat(msg.info.origin.position.x + width / 2).toFixed(4), y: parseFloat(msg.info.origin.position.y + width / 2).toFixed(4) })

    // update map resolution and origin on first run for the selected map
    if (firstRun && msg.info.resolution && msg.info.origin) {
      const currentMap = context.maps.find(m => m.name === context.selected.map?.name && m.robot === context.selectedRobot?.name)
      // console.log("updating map resolution", firstRun)
      if (!currentMap) return false
      currentMap.resolution = msg.info.resolution
      currentMap.origin = msg.info.origin
      let newMaps = [...context.maps]
      newMaps[context.mapIndex] = currentMap
      context.actions.setMaps(newMaps)

      // update FMS
      /* if (context.settings.fmsConnection) {
          api.updateMap(currentMap)
        }*/
    }
  }

  // Subscribe to map feed once on load ----------------------------------------------------------

  useEffect(() => {
    // if (canvas) console.log("running map subscriber once")
    if (canvas) ROS.subscribeMapOnce(callback, canvas, context, true)
    //  if (context.selected.map === null || context.selected.map === undefined) context.actions.setSelected({map: { name: "Select map"}})
  }, [canvas, context.selected.map])

  // select last map used for this robot ----------------------------------------------------------

  useEffect(() => {

    const index = context.maps.findIndex(m => m.robot === context.selectedRobot.name && m.active)

    if (~index) {
      !context.maps[index]?.robotId && addRobotId(context.robotInfo?._id, index)
    }

    context.actions.setMapIndex(index)
    context.maps && context.maps[index] && context.actions.setSelected({ map: context.maps[index] })
    context.actions.setMapObjects(context.maps[index]?.objects ? context.maps[index]?.objects : {})

    console.log("init map index and selected", index, context.maps[index])

  }, [context.selectedRobot])

  useEffect(() => {

    if (canvas) {
      //console.log("subscribing to map")
      window.mapSubscriber = ROS.subscribeMap(callback, canvas, context, false)
    }


  }, [context.selected.map?.name, context.loadingMap])

  useEffect(() => {

    if (!context.loadingMap && context.selected?.map) {
      sendNavInfo(convertNavInfo())
    }

  }, [context.loadingPoints, context.selected?.map])

  const addRobotId = (robotId, indexMap) => {

    if (robotId && indexMap) {
      let maps = [...context.maps]

      const newMap = { ...context.maps[indexMap], robotId: robotId }
      maps[context.mapIndex] = newMap

      context.actions.setMaps(maps)
    }

  }

  // select new tool ----------------------------------------------------------------------------

  const selectTool = (target) => {
    context.actions.setHelpers({ ...context.helpers, newItem: true })
    setModalData(null)
    const tool = target === '' ? null : context.drawTools.find(map => map._id === target)
    console.log("selecting tool", tool)
    if (tool) context.actions.setSelected({ tool: tool, connect: null })
  }

  // save area info -------------------------------------------------------------------------

  const saveAreaInfo = () => {
    setModal(false)
  }

  // validate area data (close objects) ----------------------------------------------------------------------------

  const validateArea = () => {

    let newObjects = { ...context.mapObjects }

    newObjects.areas && newObjects.areas.forEach((area, idx) => {
      if (Array.isArray(area.points) && area.points[0] !== area.points[area.points.length - 1])
        newObjects.areas[idx].points.push(area.points[0])
    })

    context.actions.setMapObjects(newObjects)
  }

  // undo -----------------------------------------------------------------------------------------------------
  const undo = () => {

    // remove point
    let newObjects = { ...context.mapObjects }

    if (context.selected.tool?._id === "points") {

      if (newObjects.points.length <= 0) return false

      // remove connected edges, if present
      let lastPoint = newObjects.points[newObjects.points.length - 1]

      if (newObjects.edges && modal === 'edges') {
        let newEdges = newObjects.edges.filter(edge => {
          //  console.log("finding edge", edge.from, edge.to, lastPoint?.name, lastPoint?.name.indexOf(edge.from), lastPoint?.name.indexOf(edge.to))
          if (lastPoint?.name.indexOf(edge.from) < 0 && lastPoint?.name.indexOf(edge.to) < 0) {
            return edge
          }
        })

        //console.log("undoing edges", newEdges, newObjects.edges, lastPoint)
        newObjects.edges = newEdges
      }

      // remove nav point
      if (newObjects.points.length > 0) newObjects.points.pop()

    } else { // remove non nav point
      if (newObjects[context.selected.tool?._id]?.length > 0) {
        if (newObjects[context.selected.tool?._id][(newObjects[context.selected.tool?._id].length - 1)].polygon.length > 1) {
          newObjects[context.selected.tool?._id][(newObjects[context.selected.tool?._id].length - 1)].polygon.pop() // pop point
        } else {
          newObjects[context.selected.tool?._id].pop() // pop area
          context.actions.setHelpers({ ...context.helpers, newItem: true })
        }
      }
    }

    context.actions.setMapObjects(newObjects)
  }

  const validatePointName = (name) => {

    let names = []

    if (context.mapObjects?.points) {
      context.mapObjects.points.forEach(e => {
        names = names.concat(e.name)
      })
    }

    return names.includes(name)

  }
  const validatePoint = name => validatePointName(name)

  // connect points -------------------------------------------------------------------------------------

  const connect = (pointData) => {

    if (!modalData?.name) {
      context.actions.setMessage({ message: "Please add an ID", type: "error" })
      return false
    }

    setModal(null)
    console.log("connecting points from", pointData.name[0])
    context.actions.setSelected({ connect: { from: pointData.name[0] } })
  }

  // cancel connect ----------------------------------------------------------------------------------------

  const cancelConnect = () => {
    context.actions.setSelected({ connect: null })
    if (context.selected.tool._id !== "points") context.actions.setHelpers({ ...context.helpers, newItem: true })
  }

  // save edge -----------------------------------------------------------------------------------

  const saveEdge = () => {
    setModal(null)
    context.actions.setHelpers({ ...context.helpers, editing: false })
    context.actions.setSelected({ connect: null })
  }

  // delete object ---------------------------------------------------------------------------------------

  const deleteObject = () => {

    let newObjects = { ...context.mapObjects }, newEdges

    switch (context.selected.mapObject?.type) {
      case "points":
        let deletedPoint = newObjects.points[context.selected.mapObject.idx]
        newObjects.points.splice(context.selected.mapObject.idx, 1)
        // console.log("delete: new points", newObjects.points, context.selected.mapObject)

        // remove connected edges, if present
        newEdges = newObjects.edges.filter(edge => deletedPoint.name.indexOf(edge.from) < 0 && deletedPoint.name.indexOf(edge.to) < 0)

        newObjects.edges = newEdges
        // console.log("delete: new edges", newEdges, deletedPoint)
        break
      case "edges":
        newObjects.edges.splice(context.selected.mapObject.idx, 1)
        // console.log("delete: new edges", newObjects.edges, context.selected.mapObject)
        break
      case "areas":
        newObjects.areas.splice(context.selected.mapObject.idx, 1)
        // console.log("delete: new areas", newObjects.areas, context.selected.mapObject)
        break
      default:
    }

    context.actions.setSelected({ mapObject: null, connect: null })
    setModalData(null)
    context.actions.setMapObjects(newObjects)
    context.actions.setHelpers({ ...context.helpers, editing: false })
    setModal(null)
  }

  // modal toggling -------------------------------------------------------------------------------

  let modalTitle, modalButtonLabel, modalButtonAction, modalComponent

  switch (modal) {
    case "reset": {
      if (context.selected.map) {
        modalTitle = "Reset Items"
        modalButtonLabel = "Reset"
        modalButtonAction = resetMap
        modalComponent = <ModalReset />
      } else {
        context.actions.setMessage({ message: "Select a map first", type: "error" })
        closeModal()
      }
      break
    }
    case "areas": {
      modalTitle = "Area"
      modalButtonLabel = "Save"
      modalButtonAction = savePoint
      modalComponent = <ModalArea velocity={modalData?.velocity} beep={modalData?.beep} update={updateModalData} />
      break
    }
    case "map": {
      modalTitle = "Map Name"
      modalButtonLabel = "Save"
      modalButtonAction = saveMap
      modalComponent = <ModalMap mapName={modalData?.mapName} update={updateModalData} />
      break
    }
    case "soft-map": {
      saveMapImprove()
      break
    }
    case "delete": {
      modalTitle = "Delete Map"
      modalButtonLabel = "Yes"
      modalButtonAction = deleteMap
      modalComponent = <ModalDelete mapName={context.selected.map?.name} />
      break
    }
    case "points": {
      modalTitle = "Point"
      modalButtonLabel = "Save"
      modalButtonAction = savePoint
      modalComponent = <ModalPoint pointData={modalData} update={updateModalData} connect={connect} validatePointName={validatePoint} />
      break
    }
    case "location": {
      modalTitle = "Point"
      modalButtonLabel = "Save"
      modalButtonAction = savePoint
      modalComponent = <ModalPoint pointData={modalData} update={updateModalData} connect={connect} validatePointName={validatePoint} />
      break
    }
    case "edges": {
      modalTitle = "Edge"
      modalButtonLabel = "Save"
      modalButtonAction = saveEdge
      //("detected edge modal")
      modalComponent = <ModalEdge edgeData={modalData} update={updateModalData} />
      break
    }
    default:
  }

  // helpers ------------------------------------------------------------------------------------

  let tool = context.selected.tool?._id

  return (
    <>

      {/* Top Actions */}

      <div className="mb-5">
        <span>
          <div className="select">
            <select className="select mb-2 mr-2" onChange={selectMap} value={context.selected.map?.name} style={{ minHeight: "2.5rem" }} disabled={isMapping || context.loadingMap} >
              <option disabled>Select map</option>
              {context.maps.map((m, i) =>
                m.robot === context.selectedRobot?.name &&
                <option key={i} value={m?.name}>
                  {m?.name?.length >= 15 ? m?.name?.slice(0, 12) + "..." : m?.name?.slice(0, 14)}
                </option>
              )}
            </select>
          </div>

          {!isMapping && !context.savingStatus ? (
            <>
              <button className="button is-primary mr-2" onClick={() => toggleMapping("map")}>
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faMapMarked} className="is-small mr-1" />
                </span>
                {!orientation &&
                  <span className="is-size-7" >Map</span>
                }
              </button>

              <button className={context.selected.map && context.selected.map.name && (context.selected.map.name !== "Select map") ? "button is-primary is-outlined mr-2" : "button is-hidden"} onClick={() => toggleMapping("soft-map")}>
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faRetweet} className="is-small" />
                </span>
              </button>

              <button
                className={context.selected.map && context.selected.map.name && (context.selected.map.name !== "Select map") ? "button is-danger " : "button is-hidden"}
                onClick={() => openModal("delete")}
              >
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faTrash} className="is-small" />
                </span>
              </button>
              {context.settings.fmsConnection &&
                <button className="button is-warning is-light ml-2" onClick={exportMaps}>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faMapSigns} className="is-small mr-1" />
                  </span>
                  <span className="is-size-7">Sync to FMS</span>
                </button>
              }
            </>
          ) : (
            <>
              {context.savingStatus ? (
                <button className="button is-danger mr-2" disabled>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faSave} className="fas fa-pulse is-small" />
                  </span>
                  {!orientation &&
                    <span className="is-size-7" > Saving </span>
                  }
                </button>
              ) : (
                <button className="button is-danger mr-2" onClick={() => toggleMapping("map")}>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faCheck} className="is-small" />
                  </span>
                  {!orientation &&
                    <span className="is-size-7" > Stop </span>
                  }
                </button>
              )}

            </>
          )
          }
        </span>

      </div>

      {/* Joystick */}

      {(isMapping && modal !== "map" && !context.modalState.disconnect && !context.modalState.configuration) &&
        <div className="column has-text-white-bis">

          {/* <div className="columns is-centered">
            <div className="column has-background-info-light is-narrow">
              <button className="button is-primary" onClick={handSelected}>
                <span className="icon is-small">
                  <i className="material-icons-round">{!hand ? "pan_tool" : "pan_tool"}</i>
                </span>
                <span>{!hand ? "Right" : "Left"}</span>
              </button>
            </div>
          </div> */}

          <Joystick orientation={orientation} />
        </div>
      }

      {/* Map */}

      <div className="has-background-grey" style={{ width: "100%", height: "36rem", position: "relative" }} ref={canvasRef}>

        <Canvas orthographic camera={{ zoom: 10 }}>
          <Suspense fallback={null}>
            <Map
              canvasRef={canvasRef}
              openModal={openModal}
              modalData={(modal === "nav" || modal === "area" || modal === "point") && modalData}
              context={context}
              updateCanvas={(c) => setCanvas(c)}
            />
          </Suspense>
        </Canvas>

        {/* Tools */}

        {!context.loadingMap &&
          <div id="tools" style={{ position: "absolute", top: "1rem", left: "1rem" }}>

            <button
              className={tool === 'points' ? "button is-link mr-2" : "button is-outlined mr-2"} onClick={() => selectTool('points')}>
              <FontAwesomeIcon icon={faLocationArrow} className="is-small mr-2" />
              <span className="is-size-7">Route</span>
            </button>

            <button className={tool === 'areas' ? "button has-background-grey-light mr-2" : "button is-outlined mr-2"} onClick={() => selectTool('areas')}>
              <FontAwesomeIcon icon={faVectorSquare} className="is-small mr-2" />
              <span className="is-size-7" >Zones</span>
            </button>

            <button className={tool === 'no_go' ? "button is-danger mr-2" : "button is-outlined mr-2"} onClick={() => selectTool('no_go')}>
              <FontAwesomeIcon icon={faBan} className="is-small mr-2" />
              <span className="is-size-7">Ban</span>
            </button>

            <button className={tool === 'location' ? "button has-background-warning mr-2" : "button is-outlined mr-2"} onClick={() => locateRobot()} disabled={tool === 'no_go' || tool === 'areas' || !tool} >
              <FontAwesomeIcon icon={faMapPin} className="is-small mr-2" />
              <span className="is-size-7">Here</span>
            </button>

            {context.selected.connect &&
              <button className="button is-link is-focused" onClick={cancelConnect}>
                Cancel Connect
              </button>
            }

          </div>
        }

        {/*Loading map */}

        {context.loadingMap &&

          <div id="tools" style={{ position: "absolute", top: "50%", width: "100%", textAlign: "center" }}>
            <button className="button is-primary mr-2" disabled>
              <span className="icon is-small">
                <FontAwesomeIcon icon={faSpinner} className="fas fa-pulse is-small" />
              </span>
              {orientation &&
                <span className="is-size-7" > Loding </span>
              }
            </button>
          </div>
        }

        {/* Actions */}

        {!context.loadingMap &&
          <div id="actions" style={{ position: "absolute", bottom: "1rem", left: "1rem" }}>

            {(!modal) &&
              <span className="is-pulled-right" style={{ marginRight: "0.5rem" }}>
                <button className="button is-danger is-outlined" onClick={undo}>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faReply} className="is-small mr-1" />
                  </span>
                  <span className="is-size-7">Undo</span>
                </button>
              </span>
            }

            <button className="button is-success" onClick={save} style={{ marginRight: "0.5rem" }}>
              <span className="icon is-small">
                <FontAwesomeIcon icon={faSave} className="is-small" />
              </span>
              {!orientation &&
                <span className="is-size-7">Save</span>
              }
            </button>

            <button className="button is-danger" onClick={() => openModal("reset")} style={{ marginRight: "0.5rem" }}>
              <span className="icon is-small">
                <FontAwesomeIcon icon={faUndo} className="is-small mr-1" />
              </span>
              {!orientation &&
                <span className="is-size-7 has-text-white">Reset</span>
              }
            </button>

            <button className={context.selected.map ? "button is-danger" : "button is-hidden"} onClick={() => openModal("delete")} style={{ marginRight: "0.5rem" }}>
              <span className="icon is-small">
                <FontAwesomeIcon icon={faTrash} className="is-small" />
              </span>
            </button>
          </div>
        }
      </div>


      {/* Message */}

      <ShowMessage />

      {/* Modal */}

      <div id="modal" className={modal ? "modal is-active" : "modal"} ref={modalRef}>
        <div className="modal-background"></div>
        <div className="modal-card">

          <header className="modal-card-head">
            <p className="modal-card-title">
              {modalTitle}
            </p>
            <button className="delete" onClick={closeModal} aria-label="close"></button>
          </header>

          <section className="modal-card-body">

            {modal && modalComponent}

            <ShowMessage />

          </section>

          <footer className="modal-card-foot">

            <button className="button is-success" onClick={modalButtonAction}>
              <span className="icon is-small">
                <FontAwesomeIcon icon={faSave} className="is-small" />
              </span>
              <span>
                {modalButtonLabel}
              </span>
            </button>

            {context.helpers.editing &&
              <button className="button is-danger" onClick={deleteObject} style={{ marginRight: "0.5rem" }}>
                Delete
              </button>
            }

            <button className="button" onClick={closeModal}>
              Cancel
            </button>

          </footer>

        </div>
      </div>
    </>
  )
}
