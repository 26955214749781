import React, { useState ,useContext } from "react"
import Context from "../../../utils/Context"

const ModalPoint = ({ pointData, update, connect, validatePointName }) => 
{
  
  const context = useContext(Context)
  const [name, setName] = useState(null)
  
  const addName = () => {
    let names = pointData?.name ? pointData.name : []
    validatePointName(name)
    if (!name || validatePointName(name)) {
      context.actions.setMessage({message: "Invalid name" , type: "error"})  
      return false
    }
      
    names.push(name)
    setName(null)
    update("name", names)
  }

  const removeName = (name) => {
    let names = pointData?.name
    if (!names) return false
    names.splice(names.indexOf(name), 1)
   //("removed name", names, name)
    update("name", names)
  }

  const enterPressed = (event) => {
    if (event.charCode === 13) {
      addName()
    }
  }
  
  return (
    <div className="controls" onKeyPress={enterPressed}>
      <div className="control">
        <input
          type="text"
          placeholder="Type an ID"
          value={name ? name : ""}
          autoFocus
          className="input"
          onChange={(e) => setName(e.target.value)}
          style={{ width: "10rem", marginRight: "0.5rem" }}
        />

        <button className="button is-link is-focused" style={{ marginRight: "1rem", marginBottom: "1rem"}} onClick={addName}>
          Add
        </button>

        <button className="button is-link is-focused" onClick={() => connect(pointData)}>
          Connect to...
        </button>
      </div>

      {pointData?.name && (
        <div className="field" style={{ marginTop: "1rem" }}>
          <div className="control">
            <div className="tags">
              {pointData.name.map((name, index) => (
                <span key={index} className="tag is-saffron">
                  { name }
                  <button
                    className="delete is-small"
                    onClick={() => removeName(name)}
                  ></button>
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
      
    </div>
  )
}

export default ModalPoint
