import React, { useState, useContext } from "react"
import history from "../../utils/history"
import api from "../../api/api"
import Context from "../../utils/Context"
import Logo from "../../assets/images/logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons'

const LoginForm = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState(null)
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsloading] = useState(false)
  const context = useContext(Context)
  
  const handlePasswordOnChange = (e) => {
    setPassword(e.target.value)
  };
  
  const handleUsernameOnChange = (e) => {
    setUsername(e.target.value)
  };
  
  const onSubmit = async () => {
    if (username && password) {
      setErrorMessage("")
      setIsloading(true)

      try {
        const res = await api.connect(username, password)
        if (!res) {
          setErrorMessage("Invalid credentials.")
          setTimeout(() => { setErrorMessage("") }, 3600)
          setIsloading(false)
        }

        if (res.token) {
          context.actions.setApiToken(res.token)
          window.apiToken = res.token
          context.actions.setUser(res)
          history.push("/")
        }
      } catch (error) {
       
        setErrorMessage("Invalid credentials.")
        setTimeout(() => { setErrorMessage("") }, 3600)
        setIsloading(false)
      }
    } else {
      setErrorMessage("Please fill all fields.")
      setTimeout(() => { setErrorMessage("") }, 3600)
    }
  }

  //console.log("login render")

  return (
    <div className="login-form">
      <figure>
        <img
          width="160"
          height="38"
          alt="logo-follow"
          src={Logo}
          className="mb-6"
        ></img>
      </figure>

      <div className="field">
        <p className="title is-size-2-desktop is-size-2-tablet is-size-3-mobile mb-6 is-family-monospace">
          Sign In
        </p>
        <p className="control has-icons-left mb-5">
          <input
            className={errorMessage ? "input is-danger" : "input"}
            type="text"
            placeholder="Username"
            onChange={handleUsernameOnChange}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faUser} className="fas" />
          </span>
        </p>
      </div>
      <div className="field">
        <p className="control has-icons-left mb-5">
          <input
            className={errorMessage ? "input is-danger" : "input"}
            type="password"
            placeholder="Password"
            onChange={handlePasswordOnChange}
          />
          <span className="icon is-small is-left">
           <FontAwesomeIcon icon={faLock} className="fas" />
          </span>
        </p>
      </div>
      <div className="field">
        <p className="control">
          <button
            className={
              isLoading
                ? "button is-success is-fullwidth has-background-primary is-loading"
                : "button is-success is-fullwidth has-background-primary"
            }
            onClick={() => onSubmit()}
          >
            Enter
          </button>
        </p>
      </div>

      <p className="help is-danger">{errorMessage}</p>
    </div>
  );
};

export default function Login() {
  return (
    <div
      className="columns is-tablet is-gapless has-background-white"
      style={{ height: "100vh" }}
    >
      <div className="column  has-background-primary"></div>
      <div className="column is-flex" style={{ justifyContent: "center" }}>
        <LoginForm />
      </div>
    </div>
  );
}
