import React from "react"

const ModalDelete = (props) => {

    return (
      <div className="controls">
        <div className="control mb-3">
          <label>Are you sure on delete Map {props.mapName ? props.mapName : ""} ? </label>
        </div>
      </div>
    )
  
  
  }
  
  export default  ModalDelete