import { useEffect, useContext } from "react"
import Context from "./Context"

const Init = () => 
{
  const context = useContext(Context)

  // store fms connection settings in an accessible way for api.js to use
  useEffect(() => {
    if (context.settings) {
      window.settings = context.settings
      window.fmsApiLink = "http://" + context.settings.fmsIp + ":30303/"
    }
  }, [context.settings])

  useEffect(() => {
    var supportsOrientationChange = "onorientationchange" in window,
      orientationEvent = supportsOrientationChange ? "orientationchange" : "resize"
    window.addEventListener(orientationEvent, function () {
      if (window.innerWidth < 800 && window.innerHeight > 600) {
        context.actions.setOrientation(false)
      } else {
        context.actions.setOrientation(true)
      }
    })
}, [])


  return null
}

export default Init