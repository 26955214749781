import Timer from "./TimerROS"
import ROSLIB from "roslib"

export default function ROS() {}

ROS.connect = function (server) {
  setInterval(() => {
    if (!window.rosConnected) {
      window.ros = new ROSLIB.Ros({
        url: "ws://192.168.1." + server + ":9090",
        groovyCompatibility: false,
        transportLibrary: "websocket",
      });

      window.ros.on("connection", () => {
        console.log(
          "Connected to websocket server.",
          `192.168.1.${server}:9090`
        );
        window.rosConnected = true;
      });

      window.ros.on("error", function (error) {
        console.log("Error connecting to websocket server");
        window.rosConnected = false;
      });

      window.ros.on("close", function () {
        console.log("Connection to websocket server closed.");
        window.rosConnected = false;
      });
    }
  }, 10000);
};

ROS.subscribeLowFrequency = (topic, type, time, callback) => {
  var subscriber = null;

  var timer = new Timer(() => {
    if (subscriber === null) {
      subscriber = ROS.subscribe(topic, type, function (msg) {
        callback(msg);
        subscriber.shutdown();
        subscriber = null;
      });
    }
  }, time);

  timer.start();

  timer.shutdown = () => {
    timer.stop();
    if (subscriber !== null) {
      subscriber.shutdown();
    }
  };

  return timer;
};

ROS.subscribe = function (topicName, type, onMessage) {
  var topic = new ROSLIB.Topic({
    ros: window.ros,
    name: topicName,
    messageType: type,
  });

  if (window.ros) topic.subscribe(onMessage);

  var out = {};
  out.shutdown = function () {
    topic.unsubscribe();
  };

  return out;
};

ROS.serviceClient = function (serviceName, type) {
  var service = new ROSLIB.Service({
    ros: window.ros,
    name: serviceName,
    serviceType: type,
  });

  var out = {};
  out.call = function (params) {
    var request = new ROSLIB.ServiceRequest({});
    service.callService(request, function (result) {});
  };

  return out;
};

ROS.subscribeMap = (callback, canvas, context) => {
  var subscriber = null

  var timer = new Timer(() => {
    if (subscriber === null) {
      subscriber = ROS.subscribe("/map", "nav_msgs/OccupancyGrid", function (msg) {
        // console.log("subscribe map got message")
        callback(msg, canvas, context)
        subscriber.shutdown()
        subscriber = null
      });
    }
    // console.log("tick tack timer map subscribe")
  }, 5000)

  timer.start()

  timer.shutdown = () => {
    // console.log("stopping timer")
    timer.stop()
    if (subscriber !== null) {
      // console.log("shutting down map subscriber")
      subscriber.shutdown()
    }
  }

  return timer
}

ROS.subscribeMapOnce = (callback, canvas, context, firstRun) => {
  let subscriber = ROS.subscribe("/map", "nav_msgs/OccupancyGrid", function (msg) {
    // console.log("subscribe map got message")
    callback(msg, canvas, context, firstRun)
    subscriber.shutdown()
    subscriber = null
  })
}