import React, { useState, useContext } from "react"
import Context from "../utils/Context"
import ShowMessage from "../components/ShowMessage"

export default function ModalConfiguration() {

    const context = useContext(Context)
    const [typeRobot, setTypeRobot] = useState()
    const [robotName, setRobotName] = useState()
    const [robotIp, setRobotIp] = useState()
    

    const ipFilter = (ip) => {
        if (/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/.test(ip)) return true
        context.actions.setMessage({message: "Invalid Ip" , type: "error"}) 
        return false
    }

    // insert or update item to array helper
    /* const pushToArray = (obj, arr) => {
 
         validadeRoboConfiguration(obj, arr)
 
         const index = arr.findIndex((e) => e.ip === obj.ip || e.name === obj.name)
         if (index === -1) arr.push(obj)
         else arr[index] = obj
         return arr
     }*/

    const validadeRoboConfiguration = (data, arr) => {

        if (arr.find((e) => e.name === data.name)?.name) {
            context.actions.setMessage({message: "Robot name exists." , type: "error"})
        } else if (arr.find((e) => e.ip === data.ip)?.ip) {
            context.actions.setMessage({message: "Robot Ip exists." , type: "error"}) 
        } else {
            arr.push(data)
            context.actions.setMessage({message: "Robot added." , type: "success"}) 
        }
        return arr
    }

    const validateTypeRobot = (robotName) => {
        if (context.robotsValueTypes.find((robotType) => robotType.name === robotName)) {
            return true
        } else {
            context.actions.setMessage({message: "Please choose robot type" , type: "error"}) 
            return false
        }
    }

    const selectRobotType = (event) => {

        const robotType = context.robotsValueTypes.find(
            (robotType) => robotType.name === event.target.value
        )
        //console.log('selecting robot ', robotType, event.target.value)
        robotType ? setTypeRobot(robotType.name) : context.actions.setMessage({message: "Please choose robot type" , type: "error"})
    }

    const addIp = () => {
        if (ipFilter(robotIp) && validateTypeRobot(typeRobot)) {
            let newRobot = { name: robotName, ip: robotIp, typeRobot: typeRobot }
            //const newConf = pushToArray(newRobot, [...context.robotConfiguration])
            const newConf = validadeRoboConfiguration(newRobot, [...context.robotConfiguration])
           // console.log("setting robot conf", newConf)
            newConf ? context.actions.setRobotConfiguration(newConf) : context.actions.setMessage({message: "Can not add robot" , type: "error"}) 
        }
    }

    const removeAddress = (indexAction) => {
        const robotConf = [...context.robotConfiguration]
        robotConf.splice(indexAction, 1)
        context.actions.setRobotConfiguration(robotConf)
        context.actions.setAvailableRobots([])
        if (!robotConf) context.action.setScanRobots(true)
        context.actions.setMessage({message: "Robot deleted" , type: "success"}) 
    }

    return (
        <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card" onClick="">

                <header className="modal-card-head">
                    <p className="modal-card-title">Robot Configuration</p>
                    <button
                        className="delete"
                        aria-label="close"
                        onClick={() => context.actions.setModalState({ ...context.modalState, configuration: false, firstLoad: true })}
                    ></button>
                </header>

                <section className="modal-card-body">
                    <div className="field mb-5">
                        <div className="controls">
                            <div className="control mb-3">
                                <label className="label">Name</label>
                                <input
                                    id="name"
                                    type="text"
                                    autoFocus
                                    placeholder={"Robot Name"}
                                    className="input"
                                    onChange={(e) => setRobotName(e.target.value)}
                                />
                            </div>
                            <div className="control mb-3">
                                <label className="label">IP Address</label>
                                <input
                                    id="ip"
                                    type="text"
                                    placeholder='xxx.xxx.xxx.xxx'
                                    className="input"
                                    onChange={(e) => setRobotIp(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="control mb-5">
                            <label className="label">Type</label>
                            <div className="select">
                                <select className="select" name="action" onChange={selectRobotType} defaultValue={"Robot Type..."} selected={selectRobotType && selectRobotType.name ? selectRobotType.name : ""}>
                                    <option disabled>Robot Type...</option>
                                    {context.robotsValueTypes && context.robotsValueTypes.map((item, i) => (
                                        <option key={i} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="field is-flex mb-5">
                            <button className="button is-link" onClick={addIp}>
                                Add
                            </button>
                        </div>
                        {context.robotConfiguration && (
                            <div className="field mb-5">
                                <div className="control">
                                    {context.robotConfiguration.map((robot, index) => (
                                        <div className="tags has-addons">
                                            <span key={index} className="tag is-info is-light">
                                                {!robot.value
                                                    ? robot.name
                                                    : ""
                                                }
                                            </span>
                                            <span key={index} className="tag is-info is-light">
                                                {!robot.value
                                                    ? robot.typeRobot
                                                    : ""
                                                }
                                            </span>
                                            <span key={index} className="tag is-dark">
                                                {!robot.value
                                                    ? robot.ip
                                                    : ""
                                                }
                                            </span>
                                            <span className="tag is-delete" onClick={() => removeAddress(index)}>
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="field is-flex mb-5">
                            <div className="control">
                                <button className="button is-success" onClick={() => context.actions.setModalState({ ...context.modalState, configuration: false, firstLoad: true })}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                    <ShowMessage />
                </section>
            </div >
        </div >
    )

}