import React, { useContext, useEffect } from "react"
import { watchBattery } from "../utils/ROS/ROSFunctions"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBatteryEmpty, faBatteryThreeQuarters, faBatteryHalf, faBatteryQuarter, faBatteryFull, faSpinner} from '@fortawesome/free-solid-svg-icons'
import Context from "../utils/Context"


export default function BatteryStatus() {

    const context = useContext(Context)


    useEffect(() => {
        const topic = watchBattery(context)
        return (() => topic?.unsubscribe())

    }, [])

    let iconBattery
    switch (true) {
        case context?.battery === -1 || context?.battery === null: iconBattery = faSpinner; break
        case context?.battery <= 8: iconBattery = faBatteryEmpty; break
        case context?.battery <= 25: iconBattery = faBatteryQuarter; break
        case context?.battery <= 50: iconBattery = faBatteryHalf; break
        case context?.battery <= 75: iconBattery = faBatteryThreeQuarters; break
        case context?.battery > 75: iconBattery = faBatteryFull; break
        default:
    }

    return (
        <div className="column">
            <p className="pt-2">
                <span className={iconBattery ===  faBatteryEmpty ? "icon has-text-danger ": ""} >
                    <FontAwesomeIcon icon={iconBattery} className={iconBattery === faSpinner ? "fas fa-pulse" : ""} />
                </span>
                <span className="content is-small ml-2">
                    {!context?.battery || context?.battery === -1 ? "" : context?.battery + "%"}
                </span>
            </p>
        </div>

    )
}