import React, { useState, useEffect, useContext } from "react"
import ReactNipple from "react-nipple"
import { moveRobot, watchJoystickFeedBack, watchRobotPosition } from "../utils/ROS/ROSFunctions"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import Context from "../utils/Context"

import "../assets/css/speedometer.css"

function Joystick() {

  const context = useContext(Context)



  //position robot
  useEffect(() => {
    let topic

    topic = watchRobotPosition(context)    
        
    return (() => { if (topic) topic.unsubscribe() })
  }, [context.isRobotConnected])




  // state
  // const orientation = props.orientation
  const sizeNipple = "150"
  const [timeRate, setTimeRate] = useState(500)
  const [sensitive, setSensitive] = useState(false)

  // const [data, setData] = useState()
  //const [accelerator, setAccelerator] = useState(context.acceleratorValue)
  const [disablePlus, setDisebledPlus] = useState(context.acceleratorValue >= 100 ? true : false)
  const [disableMinus, setDisebledMinus] = useState(context.acceleratorValue <= 0 ? true : false)
  const [state, setState] = useState({
    axes: [0, 0, 0, 0, 0, 0],
    buttons: [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  })


  useEffect(() => {

    //console.log(" ® ",context.joystick)

    let topic
    if (context.isRobotConnected) {
      // console.log("global modal: watch task feedback")
      topic = watchJoystickFeedBack(context)
    } else {
      // console.log("global modal: unsubscribe task feedback")
      if (topic) topic.unsubscribe()
    }
    return (() => { if (topic) topic.unsubscribe() })
  }, [context.joystick])



  const validateAccelerator = (accelerator) => {
    return accelerator = accelerator >= 100 ? 100 : accelerator <= 0 ? 0 : accelerator;
  }

  const speedControl = (value) => {

    if (value) {
      context.actions.setAcceleratorValue(validateAccelerator(context.acceleratorValue + 2))
      //Accelerator
      setState({
        axes: [0, 0, 0, 0, 0, 0],
        buttons: [0, 0, 0, 1, 1, 1, 0, 1, 0, 0, 0, 0, 0],
      })
    } else {
      context.actions.setAcceleratorValue(validateAccelerator(context.acceleratorValue - 2))
      //Reduce
      setState({
        axes: [0, 0, 0, 0, 0, 0],
        buttons: [0, 0, 0, 1, 0, 1, 1, 1, 0, 0, 0, 0, 0],
      })
    }
  }

  useEffect(() => {
   
    setDisebledPlus(context.acceleratorValue >= 100 ? true : false)
    setDisebledMinus(context.acceleratorValue <= 0 ? true : false)

    const rangeChange = () => {

      var rangeClock = document.querySelector(".meter-clock");
      var rotateClock = context.acceleratorValue >= 100 ? 100 : context.acceleratorValue <= 0 ? 0 : context.acceleratorValue;

      rangeClock.style.transform =
        "rotate(" + (-90 + (rotateClock * 180) / 100) + "deg)";

      setState({
        axes: [0, 0, 0, 0, 0, 0],
        buttons: [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      })
    }

    rangeChange()
  }, [context.acceleratorValue])

  useEffect(() => {
    moveRobot({ axes: state.axes, buttons: state.buttons })
  }, [state])

  useEffect(() => {

    let timer = setInterval(() => {
      moveRobot({ axes: state.axes, buttons: state.buttons })
    }, timeRate)
    return () => { clearInterval(timer) }

  }, [state])

  // functions
  const handleJoystickStart = (evt, data) => {
    // setData(data)
  }

  const handleJoystickEnd = (evt, data) => {
    // setData(data)
    setTimeRate(500)
    setState({
      axes: [0, 0, 0, 0, 0, 0],
      buttons: [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    })

  }

  const handleJoystickMove = (evt, data) => {
    // setData(data)

    let y = Math.sin(data.angle.radian);
    let x = Math.cos(data.angle.radian);

    if (y <= 0.3 && y >= -0.3) {
      y = 0
    }

    if (!sensitive) {
      if (y >= 0.9) {
        x = 0
        y = 1
      } else if (y <= -0.9) {
        x = 0
        y = -1
      }
    }

    setState({
      axes: [-x, y, 0, 0, 0, 0],
      buttons: [0, 0, 0, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0],
    })
    setTimeRate(50)
  }

  const handleChange = (event) => {
    setSensitive(event.target.checked)
    // console.log("Valor", event.target.checked)
  }


  return (
    <>
      <div className="checkbox">
        <input type="checkbox" onChange={handleChange} />
        Sensitive
      </div>
      <div className="is-flex is-justify-content-flex-end px-1">

        <button
          type="submit"
          className="button is-primary is-outlined mr-2"
          onClick={() => speedControl(false)}
          disabled={disableMinus}
        >
          <FontAwesomeIcon icon={faMinus} className="is-small" />
        </button>

        <svg className="meter" viewBox="8 18 275 150">
          <circle className="meter-left" r="90" cx="135" cy="160"></circle>
          <circle className="meter-center" r="90" cx="136" cy="160"></circle>
          <circle className="meter-right" r="90" cx="138" cy="160"></circle>
          <polygon className="meter-clock" points="132,145 137,55 145,145"></polygon>
          <circle className="meter-circle" r="10" cx="140" cy="145"></circle>
        </svg>

        <button
          type="submit"
          className="button is-primary is-outlined ml-2"
          onClick={() => speedControl(true)}
          disabled={disablePlus} >
          <FontAwesomeIcon icon={faPlus} className="is-small" />
        </button>

      </div>

      <div className="is-centered">
        <ReactNipple
          className="joystick is-relative container"
          options={{
            mode: "static",
            color: "hsl(219, 84%, 56%)",
            position: { top: "50%", left: "50%" },
            size: sizeNipple,
            treshold: 0.1,
          }}
          style={{
            width: 150,
            height: 200,
          }}
          onStart={handleJoystickStart}
          onEnd={handleJoystickEnd}
          onMove={handleJoystickMove}
        />
      </div>

    </>
  )
}

export default Joystick
