import React from "react"

const ModalEdge = ({ edgeData, update }) => 
{
  return (
    <div className="controls">
      <div className="control">
        <span>
            {edgeData?.from} to {edgeData?.to}
        </span>
      </div>
      <div className="control" style={{ marginBottom: "1rem", paddingTop: "1rem" }}>
        <label>Two-way: </label>
        <input
            type="checkbox"
            id="reversable"
            className="checkbox"
            checked={edgeData?.reversable ? true : false}
            onChange={(e) => update("reversable", e.target.checked)}
        />
      </div>
      <div className="control" style={{ marginBottom: "1rem" }}>
        <label>Obstacle avoidance: </label>
        <input
            type="checkbox"
            id="avoid"
            className="checkbox"
            checked={edgeData?.avoid ? true : false}
            onChange={(e) => update("avoid", e.target.checked)}
        />
      </div>
    </div>
  )
}

export default ModalEdge
