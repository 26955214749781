import React, { useState, useContext, useEffect } from "react"
import Context from "../utils/Context"
import JoystickManual from "../components/JoystickManual"
import Maps from "../pages/Maps/Maps"
import Missions from "../components/Missions"
import Settings from "../components/Settings"
import Dashboard from "../pages/Dashboard/Dashboard"
import Import from "../pages/Dashboard/Import"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faColumns, faCogs, faClipboardCheck, faGamepad, faMapMarkerAlt, faFileUpload    } from '@fortawesome/free-solid-svg-icons'


export default function RobotMenu() {

  const context = useContext(Context)

  // state
  const inicitial = ((window.innerWidth < 800 && window.innerHeight > 600) ? true : false)
  const [selectedMenu, setSelectedMenu] = useState("missions")
  //const [orientation, setOrientation] = useState(inicitial)

  
  
  if (!context.isRobotConnected) return null

  return (
    <>
      <div className="mb-5 is-flex is-justify-content-space-evenly" style={{ flexWrap: "wrap" }}>

        { context.selectedRobot?.typeRobot === "FI-250" && (
          <button
            className={
              selectedMenu === "dashboard"
                ? "button is-link is-active mb-2 mr-2 is-size-7"
                : "button is-link mb-2 mr-2 is-size-7"
            }
            onClick={() => setSelectedMenu("dashboard")}
          >
            <FontAwesomeIcon icon={faColumns} className="is-small mr-2" />              
            Orders
          </button>
        )}

        {//context.selectedRobot?.typeRobot !== "FI-250" && (
          <button
            onClick={() => setSelectedMenu("missions")}
            className={
              selectedMenu === "missions"
                ? "button has-background-light is-active mb-2 mr-2 is-size-7"
                : "button is-link mb-2 mr-2 is-size-7"
            }
          >
            <FontAwesomeIcon icon={faClipboardCheck} className="is-small mr-2" /> 
          Missions
          </button>
        //)
        }

        <button
          className={
            selectedMenu === "manual"
              ? "button has-background-light is-active mb-2 mr-2 is-size-7"
              : "button is-link mb-2 mr-2 is-size-7"
          }
          onClick={() => setSelectedMenu("manual")}
        >
           <FontAwesomeIcon icon={faGamepad} className="is-small mr-2" />           
          Manual
        </button>

        <button
          className={
            selectedMenu === "maps"
              ? "button has-background-light is-active mb-2 mr-2 is-size-7"
              : "button is-link mb-2 mr-2 is-size-7"
          }
          onClick={() => setSelectedMenu("maps")}
        >
           <FontAwesomeIcon icon={faMapMarkerAlt} className="is-small mr-2" /> 
          Maps
        </button>

        {context.selectedRobot?.typeRobot === "FI-250" ? (
          
          <button
            className={
              selectedMenu === "import"
                ? "button has-background-light is-active  mb-2 mr-2 is-size-7"
                : "button is-link  mb-2 mr-2 is-size-7"
            }
            onClick={() => setSelectedMenu("import")}
          >
             <FontAwesomeIcon icon={faFileUpload} className="is-small mr-2" /> 
            Import
          </button>
        
        ) : (

       <button
          className={
            selectedMenu === "settings"
              ? "button has-background-light is-active  mb-2 mr-2 is-size-7"
              : "button is-link  mb-2 mr-2 is-size-7"
          }
          onClick={() => setSelectedMenu("settings")}
        >
           <FontAwesomeIcon icon={faCogs} className="is-small mr-2" /> 
          Settings
        </button>
        )}
      </div>

      <div className="panel">

        {selectedMenu === "manual" && !context.modalState.configuration? 
            <JoystickManual />
          : selectedMenu === "maps" ?
            <Maps />
          : selectedMenu === "settings" ?
            <Settings />
          : selectedMenu === "import" ?
            <Import />
          : selectedMenu === "dashboard" ?
            <Dashboard />
          : selectedMenu === "missions" ?
            <Missions />
          : ""
        }

      </div>
    </>
  )
}
