import React, { useContext, useState } from "react"
import Context from "../../utils/Context"
import DashboardMissionStatus from "./DashboardMissionStatus"

const Dashboard = () => {

  const context = useContext(Context)
  const [filter, setFilter] = useState(context.importExcel)
  const [attributes, setAttributes] = useState()

  const executeMission = (attributes) => {
      setAttributes(attributes)
      context.actions.setModalState({ ...context.modalState, dashboard_status: true })
  }

  const handleChange = async (target) => {
      setFilter(context.importExcel.filter(word => word.order.toLowerCase().includes(target.value.toLowerCase())))
  }

  const orderByList = (list) => {
    //set orderId in list 
    var mapped = list.map(function (el, i) {
      return { index: i, value: el.order.toLowerCase() }
    })
    mapped.sort(function (a, b) {
      return +(a.value > b.value) || +(a.value === b.value) - 1
    })
    var result = mapped.map(function (el) {
      return list[el.index]
    })

    return result
  }

  return (    
    <div>
      <section>
        <div>
          <table className="table is-striped container">
            <thead>
              <tr>
                <th>Orders</th>
              </tr>
              <tr>
                <th><div className="field">
                  <div className="control">
                    <input className="input is-primary" onChange={(event) => handleChange(event.target)} type="text" placeholder="Filter" />
                  </div>
                </div>
                </th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th>{filter.length}</th>
              </tr>
            </tfoot>
            <tbody>
              {(filter) ? orderByList(filter).map((file, index) => (
                <tr>
                  <th><button className="button is-link is-outlined" onClick={() => executeMission(file.attributes)}>{file.order}</button> </th>
                </tr>
              )) :
                <tr>
                  <th> 
                    No orders
                  </th>
                </tr>
              }
            </tbody>
          </table>

          <div>
            { context.modalState.dashboard_status  && 
                <DashboardMissionStatus attributes={attributes}  />
            }
          </div>

        </div>
      </section>
    </div >
  
  )
}


export default Dashboard
