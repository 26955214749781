import React, { useContext, useState } from "react"
import Context from "../../utils/Context"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import xlsx from 'xlsx'
import ShowMessage from "../../components/ShowMessage"



export default function Import() 
{
  const context = useContext(Context)
  const [msg, setMsg] = useState("")

  const saveData = async (data) => {

    try {
        context.actions.setImportExcel(data)
        context.actions.setMessage({message: "Import excel saved" , type: "success"}) 
    } catch (error) {
      context.actions.setMessage({message: "Error in import" , type: "error"}) 
    }
  }

  const handleFile = (e) => 
  {
    var files = e.target.files, f = files[0]
    var reader = new FileReader()

    reader.onload = (e) =>
    {
      var data = new Uint8Array(e.target.result)
      var workbook = xlsx.read(data, { type: 'array' })

      // processar excel
      var first_worksheet = workbook.Sheets[workbook.SheetNames[0]]
      var result = xlsx.utils.sheet_to_json(first_worksheet, { header: 1 })

      if (!result) return false
      if (result && result.length < 1) return false

      let res = []

      // percorrer excel
      result.map((linha, idx) => 
      {
        // saltar cabeçalho
        if (idx < 1) return false

        // get values
        const taskId = linha[0] ? linha[0] : null 
        const circuit = linha[1] ? linha[1] : null
        const orderId = linha[2] ? linha[2] : null
        const sku = linha[3] ? linha[3] : null
        const desc = linha[4] ? linha[4].replace(/\u2013|\u2014/g, "-") : null
        const position = linha[5] ? linha[5] : null
        const quantity = linha[6] ? linha[6]/1000 : null


        res.push({task: taskId, circuit: circuit, order: orderId, sku: sku, description: desc, position: position, quantity: quantity})

        return false
      })
       
      res = groupORder(res)

      saveData(res)      
    }

    reader.readAsArrayBuffer(f)
  }

  function groupORder (res) {

    var result = []
    var results = []

    res.forEach(element => {
             
      result = res.filter(word => word.order === element.order)
      while (res.filter(word => word.order === element.order).length > 0){
           
           res.splice(res.findIndex(e => e.order === element.order),1)
      }
      results.push({order: element.order, attributes: result})
    				
    })
    
    return results

  } 


  return (
    <div>

    <div className="control mb-5">
      <label className="label">Import Excel</label>
            
            <div className="manage-screen">
            <div className='import-box-excel'>
            <input className='import-input custom-file-input' type='file' onChange={handleFile} />
        </div>

        <br/>

        <ShowMessage />
        </div>
        </div >
    </div >
  ) 
}