import React, { Component } from "react"

export default class Times extends Component {
    
    constructor(props) {
        super(props);
    }

    sumHoursByArray  = (arr) => {
        let secs = 0
        arr.map(m =>
            secs += (new Date(m.dtCompleted).getTime() - new Date(m.dtStarted).getTime()) / 1000
        )
        return secs
    }

    dateDiffByDays = (date1, date2) => {
        let DifferenceInTime = date2.getTime() - date1.getTime()
        return (DifferenceInTime / (1000 * 3600 * 24)).toFixed()
    }

    dateDiffBySeconds = (date1, date2) => {
        let DifferenceInTime = date2.getTime() - date1.getTime()
        return (DifferenceInTime / 1000).toFixed()
    }

     groupBy = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x)
            return rv
        }, {})
      }


     formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
     

     converterMinutes = (secs) => {

        if (!Object.is(secs, "NaN")) {
            const sec = parseInt(secs, 10); // convert value to number if it's string
            let hours = Math.floor(sec / 3600); // get hours
            let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
            let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
            if (hours < 10) { hours = "0" + hours; }
            if (minutes < 10) { minutes = "0" + minutes; }
            if (seconds < 10) { seconds = "0" + seconds; }

            return hours + ':' + minutes + ':' + seconds;

        } else {
            return "00:00:00"
        }


    }


}


