import React from "react"
import { Router, Switch, Route } from "react-router-dom"
import history from "./utils/history"
import ContextProvider from "./utils/ContextProvider"
import Navigation from "./components/Navigation"
import Safety from "./utils/Safety"
import Init from "./utils/Init"
import Login from "./pages/Login/Login"
import Home from "./pages/Home/Home"
import Maps from "./pages/Maps/Maps"
import Modal from "./components/ModalMission"

function App() 
{
  const routes = [
    {
      path: "/",
      exact: true,
      main: <Home />,
    },
    {
      path: "/maps",
      main: <Maps />,
    },
    {
      path: "/new-mission",
      main: <Modal />,
    },
  ]

  const MainContent = () => {
    return (
      <div className="main-content">
        <Navigation />
        {routes.map((route, index) => (
          <Route key={index} path={route.path} exact={route.exact}>
            {route.main}
          </Route>
        ))}
      </div>
    )
  }

  return (
    <div className="App">
      <ContextProvider>
        <Init />
        <Safety />
        <Router basename="/" history={history}>
          <Switch>
            <Route path="/login" component={Login} />
            <MainContent />
          </Switch>
        </Router>
      </ContextProvider>
    </div>
  )
}

export default App
