import React, { useContext } from "react"
import Select from 'react-select'
import Context from "../utils/Context"
import packageJson from '../../package.json';

export default function Settings() {
  const context = useContext(Context)

  // update data
  const updateSettings = (item, value) => {
    // console.log("update event", value)
    const val = { ...context.settings, [item]: item === "fmsConnection" || item === "fmsIp" ? value : Number.parseFloat(value) }
    window.settings = val
    // console.log("setting settings", val, context.settings.fmsConnection, window.settings.fmsIp)
    context.actions.setSettings(val)
  }

  // validate IP
  const validateIP = (ip) => {
    if (/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/.test(ip)) return true
    return false
  }

  const resetNavItems = () => {
    let newMaps = context.maps.map(m => ({ ...m, objects: {} }))
    console.log("maps", context.maps, newMaps)
    context.actions.setMaps(newMaps)
  }

  //console.log("fmsConnection", context.settings.fmsConnection)

  const connectionOptions = [
    { id: "1", value: false, label: "No" },
    { id: "2", value: true, label: "Yes" }
  ]

  const handleChange = (event) => {
    context.actions.setDiagnosticSwitch(event.target.checked)
  }

  return (
    <div>

      <div className="control mb-5">
        <button
          className="delete is-pulled-right"
          aria-label="close"
          onClick={() => context.actions.setModalState({ ...context.modalState, settingsRemote: false })}
        ></button>
        <label className="label">FMS connection</label>

        <Select
          value={connectionOptions.filter(o => o.value === context.settings.fmsConnection)}
          onChange={(e) => updateSettings("fmsConnection", e.value)}
          options={connectionOptions}
        />
      </div>

      <div className="control mb-5">
        <label className="label">FMS server IP</label>

        <input
          type='text'
          value={context.settings?.fmsIp ? context.settings.fmsIp : ''}
          onChange={(e) => updateSettings("fmsIp", e.target.value)}
          placeholder='xxx.xxx.xxx.xxx'
          style={validateIP(context.settings.fmsIp) ? {} : { color: "red" }}
          title='FMS IP'
        />
      </div>

      <div className="control mb-5">
        <label className="label">Cleanup nav objects</label>

        <button
          className="button"
          onClick={resetNavItems}
        >Cleanup</button>
      </div>
      <div className="control mb-5">
        <p><strong>Diagnostic</strong></p>
        <div className="checkbox">
          <input type="checkbox" onChange={handleChange} checked={context.diagnosticSwitch}/>
        </div>
      </div>

      <div>
        v_{packageJson.version}
      </div>
    </div>
  )
}