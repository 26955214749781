import axios from "axios"

export default function api() {}

api.connectOffline = async (user, pass) => {
  if (user === "fi-remote" && pass === "(KiWi)mgu[369]remote") return {token: "success", name: "remote", owner: "FI", _id: "1"}
  console.log("check failed")
  return false
}


api.connect = async (user, pass) => {
  // console.log("api connecting login", { uname: user, passw: pass }, window.fmsApiLink)
  return await axios.create({ baseURL: window.fmsApiLink }).post("users/login", { uname: user, passw: pass })
}
/*
api.connect = async (user, pass) => {
  return await api.call.post("users/login", { uname: user, passw: pass })
}*/

api.authCheck = async () => {
  const apiToken = JSON.parse(localStorage.getItem('apiToken'))
  return await axios.create({ baseURL: window.fmsApiLink }).get('users/me', { headers: { "Authorization" : `Bearer ${apiToken}` }})
}

api.generateSonaeMission = async (data) => { // expects: { robotId: "our-robot-id", orderCode: "sonae-order-code"}
  const apiToken = JSON.parse(localStorage.getItem("apiToken"))
  // console.log("api calling", data)
  return await axios.create({ baseURL: window.fmsApiLink }).post("circuit/run", data, {
    headers: { Authorization: `Bearer ${apiToken}` },
  })
}

api.saveCircuit = async (circuit) => {
  const apiToken = JSON.parse(localStorage.getItem("apiToken"))
  // console.log("api token", apiToken)
  return await axios.create({ baseURL: window.fmsApiLink }).post("circuit/new", circuit, {
    headers: { Authorization: `Bearer ${apiToken}` },
  })
}

api.saveMap = async (map) => {
  const apiToken = JSON.parse(localStorage.getItem("apiToken"))
  // console.log("api save map", map, id)
    return await await axios.create({ baseURL: window.fmsApiLink }).post("map/new", map, {
      headers: { Authorization: `Bearer ${apiToken}` },
    })
}

/*
api.createMap = async map => {
  const apiToken = JSON.parse(localStorage.getItem("apiToken"))
  console.log("creating map", map, window.settings.fmsIp)
  const res = await axios.create({ baseURL: window.fmsApiLink }).post('map/new', map, { headers: { Authorization: `Bearer ${apiToken}`, } })
  return res
}*/

api.updateMap = async map => {
  const apiToken = JSON.parse(localStorage.getItem("apiToken"))
  // console.log("api updating fms map", map)
  const res = await axios.create({ baseURL: window.fmsApiLink }).put('map/' + map._id, map, { headers: { Authorization: `Bearer ${apiToken}`, } })
  return res
}



api.getRobotInfo = async robotName => {
  const apiToken = JSON.parse(localStorage.getItem("apiToken"))
  // console.log("api token", apiToken, robotName)
  const res = await axios.create({ baseURL: window.fmsApiLink }).post('robots', { name: robotName }, { headers: { Authorization: `Bearer ${apiToken}`, } })
  // console.log("fms robot info", res)
  return res
}

api.updateAreaMap = async (areaId, mapId) => {
  const apiToken = JSON.parse(localStorage.getItem("apiToken"))
  // console.log("updating area map", areaId, mapId)
  const res = await axios.create({ baseURL: window.fmsApiLink }).put('area/' + String(areaId), { mapId: mapId }, { headers: { Authorization: `Bearer ${apiToken}`, } })
  return res
}

api.getMap = async mapName => {
  const apiToken = JSON.parse(localStorage.getItem("apiToken"))
  // console.log("api getting map info", mapName)
  const res = await axios.create({ baseURL: window.fmsApiLink }).post('map/', { name: mapName }, { headers: { Authorization: `Bearer ${apiToken}`, } })
  return res
}

api.fetchMaps = async (query) => {
  const apiToken = JSON.parse(localStorage.getItem("apiToken"))
  return await axios.create({ baseURL: window.fmsApiLink }).post("map", query, {
    headers: { Authorization: `Bearer ${apiToken}` },
  }).catch(e => console.log("catched error", e))
}