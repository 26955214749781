import React from "react"

const ModalReset = () => 
{
  return (
    <div className="controls">
      <p>
        This will clear all navigation items, are you sure?
      </p>
    </div>
  )
}

export default ModalReset
