import { useContext, useEffect } from "react"
import Context from "../Context"
import ROSLIB from "roslib"

// remote control
export const moveRobot = (payload) => {

 // console.log("saga: moving robot to ", payload.axes[0], payload.axes[1])

  var topic = new ROSLIB.Topic({
    ros: window.ros && window.ros,
    name: "/wgo/remote/joy",
    messageType: "sensor_msgs/Joy",
  })

  var comm = new ROSLIB.Message(payload)
  topic.publish(comm)
}

// send task to robot
export const sendTaskToRobot = (payload) => {

  var info = new ROSLIB.Topic({
    ros: window.ros && window.ros,
    name: "/wgo/fms/currentTask_hack",
    messageType: "custom_msgs/Task",
  })

  console.log("sending task to robot:", payload.name)
  var comm = new ROSLIB.Message(payload)
  info.publish(comm)
}

// cancel actions
export const cancelActions = (payload) => {

  var info = new ROSLIB.Topic({
    ros: window.ros && window.ros,
    name: "/wgo/fms/cancelTask",
    messageType: "custom_msgs/Cancel",
  })

  console.log("sending cancellation to robot (saga)", payload)
  var comm = new ROSLIB.Message(payload)
  info.publish(comm)
}

// on-off devices actions
export const sendNavInfo = (payload) => {

  var info = new ROSLIB.Topic({
    ros: window.ros && window.ros,
    name: "/wgo/fms/navInfo",
    messageType: "custom_msgs/NavInfo",
  })

  console.log("sending nav info to robot")
  var comm = new ROSLIB.Message(payload)
  info.publish(comm)
}

// end of mission
export const watchEndOfMission = (context) => {
  // setInterval(() => {
  // console.log("watchEndOfMission", window.ros)
  if (window.ros) {
    const status = new ROSLIB.Topic({
      ros: window.ros && window.ros,
      name: "/wgo/task_manager/TaskFeedback",
      messageType: "custom_msgs/TaskFeedback",
    })

    // console.log("starting to watch end of mission")

    status.subscribe((message) => {
      context.actions.setEndOfMission(message)
    })

    return status
  }
}

export const watchJoystickFeedBack = (context) => {
  // setInterval(() => {

  if (window.ros) {
    const status = new ROSLIB.Topic({
      ros: window.ros && window.ros,
      name: "/wgo/teleop_remote/cmd_vel_desired",
      messageType: "geometry_msgs/Twist",
    })

    status.subscribe((message) => {
      //Removed zero velocity in robot
      if (message.angular.z === 0) {
        moveRobot({ axes: [0, 0, 0, 0, 0, 0], buttons: [0, 0, 0, 1, 1, 1, 0, 1, 0, 0, 0, 0, 0], })
      }
    })

    return status
  }
}


export const watchRobotPosition = (context) => {

  // setInterval(() => {

  if (window.ros) {
    const status = new ROSLIB.Topic({
      ros: window.ros && window.ros,
      name: "/wgo/current_position",
      messageType: "geometry_msgs/Pose",
    })

    status.subscribe((message) => {
      context.actions.setRobotPosition(message)
    })
    return status
  }
  //}, 1000)
}


/*
export const watchMapping = (context) => {
  //  setInterval(() => {
  // const status = new ROSLIB.Topic({
  //   ros: window.ros && window.ros,
  //   name: "/wgo/task_manager/TaskFeedback",
  //   messageType: "custom_msgs/TaskFeedback",
  // })
  // status.subscribe((message) => {

  //   if (message?.status === "executing" && message?.actions[0]?.name === "MappingMachine") {
  //     context.actions.setMappingStatus(true)
  //   } else {
  //     context.actions.setMappingStatus(false)
  //   }

  // })

}*/


export const watchBattery = (context) => {
  setInterval(() => {

    if (window.ros) {
      const battery = new ROSLIB.Topic({
        ros: window.ros && window.ros,
        name: "/wgo/battery_indicator/battery_status",
        messageType: "sensor_msgs/BatteryState",
      })

      battery.subscribe(function (message) {
        context.actions.setBattery(message.percentage)
        battery.unsubscribe()
      })
    } else {
      context.actions.setBattery(-1)
    }

  }, 10000)
}

export const watchDiagnostic = (context) => {

  setInterval(() => {
    if (window.ros) {
            
      const status = new ROSLIB.Topic({
        ros: window.ros && window.ros,
        name: "/wgo/alive_signal/system_diagnostic",
        messageType: "std_msgs/String",
      })

       status.subscribe((message) => {
     //  status.subscribe((message) => {
        if (context.diagnostic?.data === message?.data || context.diagnostic === "")
          context.actions.setDiagnosticTime(new Date())
          context.actions.setDiagnostic(message)
          
         status.unsubscribe()
      })     
    }
    
  }, 5000)

}


// fire default topic listeners
export default function ROSFunctions() {

  const context = useContext(Context)

  useEffect(() => {

    // person

    const watchNewPerson = () => {
      // setInterval(() => {
      const person = new ROSLIB.Topic({
        ros: window.ros && window.ros,
        name: "/wgo/user_interaction/person_detection",
        messageType: "custom_msgs/Interaction",
      })

      //console.log("starting to watch new person")

      person.subscribe((message) => {
        context.actions.setFoundPeople(message.new_person ? true : false)
        // person.unsubscribe()
      })
      // }, 1000)
    }

    const watchStatusRobot = () => {
      setInterval(() => {
        const status = new ROSLIB.Topic({
          ros: window.ros && window.ros,
          name: "/wgo/task_manager/TaskFeedback",
          messageType: "custom_msgs/TaskFeedback",
        })
        if (window.ros) {
          status.subscribe((message) => {
            // console.log("feedback message", message)

            if (message?.status === "executing" && message?.actions[0]?.status === "blockedStatus") {
              context.actions.setRobotStatus(true)
              context.actions.setModalState({ ...context.modalState, disconnect: true })
            }
          })
        }
        status.unsubscribe()
      }, 10000)

    }

    const watchRobotPosition = () => {

      // setInterval(() => {

      if (window.ros) {
        const status = new ROSLIB.Topic({
          ros: window.ros && window.ros,
          name: "/wgo/current_position",
          messageType: "geometry_msgs/Pose",
        })

        status.subscribe((message) => {
          context.actions.setRobotPosition(message)

        })
        //  status.unsubscribe()
      }

    }

    // diagnostic
    // const watchDiagnostic = () => {
    //   setInterval(() => {
    //     const status = new ROSLIB.Topic({
    //       ros: window.ros && window.ros,
    //       name: "/wgo/diagnostic/msg",
    //       messageType: "std_msgs/String",
    //     })

    //     status.subscribe((message) => {
    //       status.unsubscribe()
    //     })
    //   }, 2000)
    // }


    // end of missions
    const watchEndOfMissionOld = () => {
      // setInterval(() => {
      const status = new ROSLIB.Topic({
        ros: window.ros && window.ros,
        name: "/wgo/task_manager/TaskFeedback",
        messageType: "custom_msgs/TaskFeedback",
      })

     // console.log("starting to watch end of mission")

      status.subscribe((message) => {
      //  console.log("Task Robot Status ", message.status)

        context.actions.setEndOfMission(message)

        //   battery.unsubscribe()
        // console.log("end of mission detected")
        //context.actions.setEndOfMission(true)
        // status.unsubscribe()
      })
      // }, 1991)
    }

    // update desinfection speeds
    const updateDesinfectionSpeeds = () => {
      setInterval(() => {
        var info = new ROSLIB.Topic({
          ros: window.ros && window.ros,
          name: "/wgo/uvrobot/desinfect_vel",
          messageType: "custom_msgs/DesinfectVel",
        })

        // console.log("sending desinfect vel to robot (saga)", window.settings ? window.settings : {})
        var comm = new ROSLIB.Message(window.settings ? window.settings : {})
        info.publish(comm)
      }, 1000)
    }

    // start default topics
    if (window.ros) {
      // watchRobotPosition()
      //watchBattery()
      // watchEndOfMission()
      //watchDiagnostic()
      // watchStatusRobot()
      //watchNewPerson()
      //updateDesinfectionSpeeds()
    }
  }, [])

  return null
}
